/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { cleanData } from "../../redux/searchOrganization/searchActions";
import { useTranslation } from "react-i18next";
import { MDBBtn } from "mdb-react-ui-kit";
import { ClapSpinner } from "react-spinners-kit";
import Icon from "react-icons-kit";
import { basic_lock_open } from "react-icons-kit/linea/basic_lock_open";
import { gift } from "react-icons-kit/feather/gift";
import { user } from "react-icons-kit/feather/user";
import { calendar } from "react-icons-kit/feather/calendar";
import { basic_world } from "react-icons-kit/linea/basic_world";
import { basic_smartphone } from "react-icons-kit/linea/basic_smartphone";
import { basic_mail } from "react-icons-kit/linea/basic_mail";
import { basic_compass } from "react-icons-kit/linea/basic_compass";
import { creditCard } from "react-icons-kit/oct/creditCard";
import { info } from "react-icons-kit/oct/info";
import Tippy from "@tippyjs/react";
import axios from "axios";

const Content = ({
  hostName,
  userData,
  setLoggedInUserData,
  setOrganizationPath,
  setOrganizationDetails,
  toggleSearchAnimation,
}) => {
  // translator
  const { t } = useTranslation();

  // get search data from redux
  const searchResult = useSelector((state) => state.searchReducer);
  const searchLoader = searchResult.loader;
  const { searchArray } = searchResult;
  const searchError = searchResult.error;

  // states
  const [btnLoader, setBtnLoader] = useState(false);
  const [loadingIndices, setLoadingIndices] = useState(new Set());

  // go to organization path and setOrganization details
  // but go to signin in case user clicks follow
  // btn and user is not registered
  const navigate = useNavigate();
  const goToCardRoute = (organization, e) => {
    if (e.target.classList.contains("follow-btn")) {
      if (!btnLoader) {
        if (!userData) {
          navigate("/signin");
        } else {
          setBtnLoader(true);
          setLoadingIndices(
            (prev) => new Set([...prev, organization.tagNumber])
          );
          const axiosConfig = {
            headers: {
              Authorization: `Bearer ${userData.token}`,
            },
          };
          axios
            .post(
              `${hostName}api/v1/donor/follow?organizationTag=${organization.tagNumber}`,
              {},
              axiosConfig
            )
            .then((res) => {
              setLoadingIndices((prev) => {
                const updated = new Set(prev);
                updated.delete(organization.tagNumber);
                return updated;
              });
              organization.isFollowing = res.data.data.isFollowing;
            })
            .catch((err) => {
              setLoadingIndices((prev) => {
                const updated = new Set(prev);
                updated.delete(organization.tagNumber);
                return updated;
              });
              if (err.response.status === 401) {
                localStorage.removeItem("user");
                setLoggedInUserData(null);
                navigate("/signin");
              }
            })
            .finally(() => setBtnLoader(false));
        }
      }
    } else {
      if (!btnLoader) {
        setOrganizationDetails(organization);
        const name = organization.shortName;
        const word = name.replace(/[^a-zA-Z0-9]/g, "");
        const lowercase = word.toLowerCase();
        setOrganizationPath(lowercase);
        navigate(`/${lowercase}`, { replace: true });
      }
    }
  };

  // my charities
  // eslint-disable-next-line no-unused-vars
  const [charityError, setCharityError] = useState(null);
  const [charityLoader, setCharityLoader] = useState(true);
  const [charityList, setCharityList] = useState(null);
  useEffect(() => {
    let user = localStorage.getItem("user");
    if (user) {
      user = JSON.parse(user);
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      };

      axios
        .get(`${hostName}api/v1/donor/follow`, axiosConfig)
        .then((res) => {
          if (res.data.length > 0) {
            setCharityList(res.data);
            setCharityError(null);
          } else {
            setCharityList(null);
            setCharityError("No Data Found");
          }
        })
        .catch((err) => {
          setCharityError(err.message);
          if (err.response.status === 401) {
            localStorage.removeItem("user");
            setLoggedInUserData(null);
            navigate("/signin");
          }
        })
        .finally(() => setCharityLoader(false));
    }
  }, []);

  // open organization
  const openOrganizationPage = (organization) => {
    const clean = organization?.shortName?.replace(/[^a-zA-Z0-9]/g, "");
    const lowercase = clean?.toLowerCase();
    navigate(`/${lowercase}`, { replace: true });
  };

  // show charities on home page
  const dispatch = useDispatch();
  const showMyCharities = () => {
    dispatch(cleanData());
  };

  return (
    <div className="home-content" style={{ position: "relative" }}>
      {/* display search loader */}
      {searchLoader ? (
        <main className="info-content-div" style={{ height: `${100}%` }}>
          <div className="loading-screen">
            <ClapSpinner size={30} color="#007c16" loading={searchLoader} />
          </div>
        </main>
      ) : (
        <>
          {searchArray && searchArray.length > 0 ? (
            <div className="cards-container">
              <div className="my-charities-div" onClick={showMyCharities}>
                {t("Header.my_charities")}
              </div>
              {searchArray.map((individualOrganizaion) => (
                <div
                  className="custom-card"
                  key={individualOrganizaion.node.tagNumber}
                  onClick={(e) => goToCardRoute(individualOrganizaion, e)}
                >
                  <header className="card-header">
                    <div className="card-leftside">
                      <div className="card-logo">
                        <img src={individualOrganizaion.logo} alt="card-logo" />
                      </div>
                      <h3>
                        {individualOrganizaion.name}{" "}
                        {individualOrganizaion.locationName &&
                          individualOrganizaion.locationName !== "" && (
                            <>- {individualOrganizaion.locationName}</>
                          )}
                      </h3>
                    </div>
                    <MDBBtn className="follow-btn" disabled={btnLoader}>
                      {individualOrganizaion.isFollowing
                        ? t("Content.following")
                        : t("Content.follow")}
                      {loadingIndices.has(individualOrganizaion.tagNumber) && (
                        <div className="btn-loader">
                          <ClapSpinner
                            size={24}
                            color="#fff"
                            loading={btnLoader}
                          />
                        </div>
                      )}
                    </MDBBtn>
                  </header>

                  <p className="city-and-country">
                    {individualOrganizaion.city},{" "}
                    {individualOrganizaion.currencyCode}
                  </p>

                  <div className="other-info">
                    <div className="info-icon-and-text">
                      <span className="info-icon">
                        <Icon icon={gift} size={24} />
                      </span>
                      <span className="info-text">
                        {individualOrganizaion.campaigns.length}{" "}
                        {t("Content.campaigns")}
                      </span>
                    </div>
                    <div className="info-icon-and-text">
                      <span className="info-icon">
                        <Icon icon={user} size={24} />
                      </span>
                      <span className="info-text">
                        {individualOrganizaion.followers}{" "}
                        {t("Content.followers")}
                      </span>
                    </div>
                    <div className="info-icon-and-text">
                      <span className="info-icon">
                        <Icon icon={calendar} size={24} />
                      </span>
                      <span className="info-text">
                        {t("Content.joined")} {individualOrganizaion.signupDate}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <>
              {userData ? (
                <>
                  {charityLoader ? (
                    <main
                      className="info-content-div"
                      style={{ height: `${100}%` }}
                    >
                      <div className="loading-screen">
                        <ClapSpinner
                          size={30}
                          color="#007c16"
                          loading={charityLoader}
                        />
                      </div>
                    </main>
                  ) : (
                    <>
                      {charityList && charityList.length > 0 ? (
                        <div className="recurring-container">
                          {charityList.map((individualOrganization) => (
                            <div
                              className={
                                !individualOrganization.active ||
                                !individualOrganization.acceptOnlineDonations ||
                                !individualOrganization.shortName
                                  ? "recurring-item-box disabled"
                                  : "recurring-item-box enabled"
                              }
                              key={individualOrganization.organizationTag}
                              style={{ borderRadius: `${10}px` }}
                              onClick={() =>
                                openOrganizationPage(individualOrganization)
                              }
                            >
                              {/* organization box */}
                              <div className="recurring-organization-box charities-card">
                                <div className="recurring-organization-img-div">
                                  <img
                                    src={individualOrganization.logo}
                                    alt="organization-logo"
                                    className="recurring-organization-img"
                                  />
                                </div>
                                <h3 className="recurring-organization-name charities-name">
                                  {individualOrganization.name}
                                </h3>
                                {/* tag */}
                                {individualOrganization.acceptOnlineDonations ? (
                                  <Tippy
                                    content={t("Content.accept_donations")}
                                  >
                                    <div className="tag">
                                      <Icon icon={creditCard} size={18} />
                                    </div>
                                  </Tippy>
                                ) : (
                                  <>
                                    {!individualOrganization.active && (
                                      <Tippy content={t("Content.closed")}>
                                        <div className="tag">
                                          <Icon icon={info} size={18} />
                                        </div>
                                      </Tippy>
                                    )}
                                  </>
                                )}
                              </div>
                              <div className="contact-box charities-contact-box">
                                <div className="contact-info-box">
                                  {/* streetAddress and unit no */}
                                  <div className="p-row">
                                    <div>
                                      <Icon icon={basic_compass} size={16} />
                                    </div>
                                    <div className="p-row-text">
                                      {individualOrganization.streetAddress}{" "}
                                      {individualOrganization.unitNumber}
                                    </div>
                                  </div>
                                  {/* city province and postal code */}
                                  <div className="p-row">
                                    <div className="empty-div" />
                                    <div className="p-row-text">
                                      {individualOrganization.city}{" "}
                                      {individualOrganization.provinceState}{" "}
                                      {individualOrganization.postalZipCode}
                                    </div>
                                  </div>
                                  {/* country */}
                                  <div className="p-row">
                                    <div className="empty-div" />
                                    <div className="p-row-text">
                                      {individualOrganization.country}
                                    </div>
                                  </div>
                                  {/* phone */}
                                  <div className="p-row">
                                    <div>
                                      <Icon icon={basic_smartphone} size={16} />
                                    </div>
                                    <div className="p-row-text">
                                      {individualOrganization.phone}
                                    </div>
                                  </div>
                                  {/* email */}
                                  <div
                                    className="p-row"
                                    style={{ overflow: "hidden" }}
                                  >
                                    <div>
                                      <Icon icon={basic_mail} size={16} />
                                    </div>
                                    <div className="p-row-text">
                                      {individualOrganization.email &&
                                      individualOrganization.email !== "" ? (
                                        <a
                                          href={`mailto:${individualOrganization.email}`}
                                        >
                                          {individualOrganization.email}
                                        </a>
                                      ) : (
                                        <span>
                                          {t("Content.not_available")}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  {/* website */}
                                  <div
                                    className="p-row"
                                    style={{ overflow: "hidden" }}
                                  >
                                    <div>
                                      <Icon icon={basic_world} size={16} />
                                    </div>
                                    <div className="p-row-text">
                                      {individualOrganization.website &&
                                      individualOrganization.website !== "" ? (
                                        <a
                                          href={individualOrganization.website}
                                          target="blank"
                                        >
                                          {individualOrganization.website}
                                        </a>
                                      ) : (
                                        <span>
                                          {t("Content.not_available")}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <br />
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <main
                          className="info-content-div"
                          style={{ height: `${100}%` }}
                        >
                          <div className="error-and-content">
                            {searchError && (
                              <div className="error-msg">{searchError}</div>
                            )}
                            <br />
                            {/* {charityError && (
                              <div className="error-msg">{charityError}</div>
                            )} */}
                            <br />
                            <div className="user-welcome-div">
                              <Icon icon={basic_lock_open} size={150} />
                              <h4 className="user-welcome-heading">
                                {t("Content.start_exploring")}
                              </h4>
                              <p className="user-welcome-text">
                                {t("Content.search_for_a_charity")}
                              </p>
                              <MDBBtn
                                type="button"
                                className="lets-explore-btn"
                                onClick={toggleSearchAnimation}
                              >
                                {t("Content.lets_explore")}
                              </MDBBtn>
                            </div>
                          </div>
                        </main>
                      )}
                    </>
                  )}
                </>
              ) : (
                <main className="info-content-div">
                  <div className="error-and-content">
                    {searchError && (
                      <div className="error-msg">{searchError}</div>
                    )}
                    <br />
                    <div className="content-box">
                      <h6 className="welcome-heading">
                        {t("Content.welcome_to_donateNOW")}
                      </h6>
                      <p className="welcome-para">
                        {t("Content.welcome_description")}
                      </p>
                      <ul className="features-list">
                        <li className="features-li">
                          {t("Content.track_donations")}
                        </li>
                        <li className="features-li">
                          {t("Content.create_recurring_donations")}
                        </li>
                        <li className="features-li">
                          {t("Content.following_charities")}
                        </li>
                        <li className="features-li">
                          {t("Content.downloading_tax_receipt")}
                        </li>
                      </ul>
                      <p className="welcome-para">
                        {t("Content.get_started_msg")}
                      </p>
                      <div className="actions-div">
                        <div className="left">
                          <MDBBtn
                            className="search-btn"
                            type="button"
                            onClick={toggleSearchAnimation}
                          >
                            {t("Content.search_as_a_guest")}
                          </MDBBtn>
                        </div>
                        <div className="right">
                          <MDBBtn
                            className="account-btn"
                            type="button"
                            onClick={() => navigate("/signup")}
                          >
                            {t("Content.create_account")}
                          </MDBBtn>
                          <MDBBtn
                            className="account-btn"
                            type="button"
                            onClick={() => navigate("/signin")}
                          >
                            {t("Content.signin")}
                          </MDBBtn>
                        </div>
                      </div>
                    </div>
                  </div>
                </main>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Content;
