/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Icon from "react-icons-kit";
import { arrows_left } from "react-icons-kit/linea/arrows_left";
import { arrows_right } from "react-icons-kit/linea/arrows_right";
import { basic_lock } from "react-icons-kit/linea/basic_lock";
import logo from "../../images/logo.png";
import { useNavigate } from "react-router-dom";
import ReactDatePicker from "react-datepicker";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import DOMPurify from "dompurify";

export const EmbeddedHowOften = ({
  organizationDetails,
  step,
  setStep,
  amount,
  setAmount,
  amountValid,
  setAmountValid,
  howOften,
  setHowOften,
  recurringValue,
  setRecurringValue,
  validRecurring,
  setValidRecurring,
  specifyTime,
  setSpecifyTime,
  lang,
  handleLanguage,
  paths,
  currentQuery,
  startDate,
  setStartDate,
  charityDetails,
  isSelectableDate,
  setNoOfDays,
}) => {
  // translation
  const { t } = useTranslation();

  // track amount and set amount valid boolean
  useEffect(() => {
    setAmountValid(
      parseFloat(amount) >= parseFloat(charityDetails.minimumAmount) &&
        amount !== ""
    );
  }, [amount]);

  // handle input onchange amount
  const handleDonationAmount = (value) => {
    if (value < 0) {
      value = 0;
    }
    setAmount(value);
  };

  // handle how often buttons
  const handleHowOften = (text) => {
    setHowOften(text);

    if (
      text === "DAILY" &&
      charityDetails?.endDate &&
      charityDetails?.endDate !== "9999-12-31 00:00:00" &&
      charityDetails?.frequency?.length === 1 &&
      charityDetails.frequency.includes("DAILY")
    ) {
      setNoOfDays(null);
      return; // Stop execution, so updateRecurringValue does not run
    }

    updateRecurringValue(text, specifyTime);
  };

  // Function to handle recurring value updates without useEffect
  const updateRecurringValue = (howOften, specifyTime) => {
    let noOfFrequency = howOften === "ONETIME" ? null : 1;

    if (howOften === "DAILY" && specifyTime) {
      noOfFrequency = 30;
    } else if (howOften === "WEEKLY" && specifyTime) {
      noOfFrequency = 52;
    } else if (howOften === "BIWEEKLY" && specifyTime) {
      noOfFrequency = 26;
    } else if (howOften === "MONTHLY" && specifyTime) {
      noOfFrequency = 12;
    } else if (howOften === "ONETIME") {
      noOfFrequency = null;
      setStartDate(new Date());
    }

    setRecurringValue(noOfFrequency);
    setValidRecurring(true);
  };

  // radio change
  const handleSpecifyTime = (boolean) => {
    setSpecifyTime(boolean);
    updateRecurringValue(howOften, boolean);
  };

  // handle input box event for no of recurrings
  const handleRecurringValue = (value) => {
    let newValue = parseInt(value);
    if ((newValue < 2 || isNaN(newValue)) && specifyTime) {
      setValidRecurring(false);
    } else if (newValue >= 2 && specifyTime) {
      setValidRecurring(true);
    }
    if (newValue < 0) {
      newValue = 0;
    }
    setRecurringValue(newValue);
  };

  // handle continue
  const handleContinue = () => {
    if (amountValid && validRecurring) {
      if (parseFloat(charityDetails.fees) > 0) {
        setStep(step + 1);
      } else {
        setStep(step + 2);
      }
    }
  };

  // percentage
  const [percentage, setPercentage] = useState(null);
  const [width, setWidth] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState(null);
  useEffect(() => {
    if (charityDetails) {
      const calculatedPercentage = (
        (charityDetails.raisedAmount / charityDetails.targetAmount) *
        100
      ).toFixed(2);
      const calculatedWidth =
        calculatedPercentage >= 100 || !isFinite(calculatedPercentage)
          ? "100%"
          : `${calculatedPercentage}%`;
      const calculatedBackgroundColor = "#007c16";

      setPercentage(calculatedPercentage);
      setWidth(calculatedWidth);
      setBackgroundColor(calculatedBackgroundColor);
    }
  }, [charityDetails]);

  // navigate
  const navigate = useNavigate();

  // is image loaded?
  const [isLoaded, setIsLoaded] = useState(false);

  // day options
  const days = [
    { value: 1, label: "MONDAY" },
    { value: 2, label: "TUESDAY" },
    { value: 3, label: "WEDNESDAY" },
    { value: 4, label: "THURSDAY" },
    { value: 5, label: "FRIDAY" },
    { value: 6, label: "SATURDAY" },
    { value: 7, label: "SUNDAY" },
  ];

  // description expansion
  const [isExpanded, setIsExpanded] = useState(false);
  const descriptionRef = useRef(null);
  const [maxHeight, setMaxHeight] = useState("7.2em");
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    if (!descriptionRef.current || !charityDetails?.description) return;

    const computedStyle = getComputedStyle(descriptionRef.current);
    const computedLineHeight = computedStyle.lineHeight;
    const lineHeight = isNaN(parseFloat(computedLineHeight))
      ? 1.2 * parseFloat(computedStyle.fontSize)
      : parseFloat(computedLineHeight);

    const paddingTop = parseFloat(computedStyle.paddingTop);
    const paddingBottom = parseFloat(computedStyle.paddingBottom);
    const fullHeight = descriptionRef.current.scrollHeight;

    const maxCollapsedLines = 6;
    const collapsedHeight =
      Math.ceil(lineHeight * maxCollapsedLines) + paddingTop + paddingBottom;

    if (fullHeight > collapsedHeight) {
      setIsOverflowing(true);
      if (!isExpanded) {
        setMaxHeight(`${collapsedHeight}px`);
      }
    } else {
      setIsOverflowing(false);
    }

    if (isExpanded) {
      setMaxHeight(`${fullHeight}px`);
    }
  }, [charityDetails?.description, isExpanded]);

  // if expanded, set maxHeight to the container fullHeight and that is scrollHeight
  useEffect(() => {
    if (isExpanded && descriptionRef.current) {
      setMaxHeight(`${descriptionRef.current.scrollHeight}px`);
    }
  }, [isExpanded]);

  return (
    <div className="home-content">
      <div className="embed-charity-details-container">
        <header className="embed-charity-details-header">
          <div onClick={() => setStep(step - 1)} className="arrow-left-span">
            <Icon icon={arrows_left} size={32} />
          </div>
          <h5 className="choose-amount">{t("DonationModal.how_often")}?</h5>
        </header>

        <div className="embed-logo-container">
          <div className="embed-campaign-logo">
            <img src={charityDetails.icon.filename} alt="card-logo" />
          </div>
          <div className="embed-charity-organization-name">
            <div className="embed-charity-name">{charityDetails.name}</div>
            <div className="embed-organization-name">
              {organizationDetails.name}
            </div>
          </div>
        </div>

        {charityDetails.targetAmount > 0 && (
          <>
            {width && backgroundColor && (
              <div className="campaign-details-progress-div">
                <div
                  className="progress-bar"
                  style={{
                    width,
                    background: backgroundColor,
                  }}
                ></div>
              </div>
            )}
          </>
        )}

        {charityDetails.targetAmount > 0 && (
          <div className="campaign-collection">
            <div className="collection-box">
              <h4>
                {organizationDetails.currencySymbol}
                {charityDetails.raisedAmount.toFixed(2)}
              </h4>
              <h6>{t("CampaignDetails.raised")}</h6>
            </div>

            <div className="collection-box">
              {percentage && (
                <>
                  <h4>
                    {percentage >= 100 || !isFinite(percentage)
                      ? 100
                      : percentage}
                    %
                  </h4>
                  <h6>{t("CampaignDetails.collection")}</h6>
                </>
              )}
            </div>
            <div className="collection-box last">
              <h4>
                {organizationDetails.currencySymbol}
                {charityDetails.targetAmount.toFixed(2)}
              </h4>
              <h6>{t("CampaignDetails.target")}</h6>
            </div>
          </div>
        )}

        <div
          className="embed-charity-msg"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "left",
            marginTop: "0",
          }}
        >
          {charityDetails?.banner && (
            <div
              className="banner-img-container"
              style={{
                margin: "20px auto",
                background: isLoaded ? "transparent" : "#f7f7f7",
              }}
            >
              <img
                src={charityDetails?.banner}
                alt="banner"
                onLoad={() => {
                  setTimeout(() => {
                    setIsLoaded(true);
                  }, 500);
                }}
                style={{
                  display: isLoaded ? "block" : "none",
                }}
              />
            </div>
          )}
          {charityDetails?.description && (
            <div className="campaign-container">
              <div
                ref={descriptionRef}
                className="campaign-description"
                style={{ maxHeight }}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(charityDetails?.description),
                }}
              />
              {isOverflowing && (
                <button
                  className="see-more-toggle-btn"
                  onClick={() => setIsExpanded(!isExpanded)}
                >
                  {isExpanded ? "See Less" : "See More"}
                </button>
              )}
            </div>
          )}
        </div>

        <div className="input-and-minimum-amount-msg-container">
          <div className="big-input-div">
            <div className="dollar">{organizationDetails.currencySymbol}</div>
            <input
              type="number"
              inputMode="decimal"
              step="0.01"
              required
              value={amount}
              onChange={(e) => handleDonationAmount(e.target.value)}
              readOnly={!charityDetails.amounts.includes(0)}
            />
          </div>
          <span
            className={`minimum-amount-msg embed${amountValid ? "" : " red"}`}
          >
            {t("CampaignDetails.minimum_amount")}{" "}
            {organizationDetails.currencySymbol}
            {charityDetails.minimumAmount}
          </span>
        </div>

        <div className="amounts-div step-3">
          {charityDetails?.frequency?.length > 0 &&
            charityDetails.frequency.map((freq, index) => (
              <div
                id={freq}
                key={index}
                className={`amounts${
                  howOften === freq ? " active" : " duration"
                }`}
                onClick={(e) => handleHowOften(e.currentTarget.id)}
              >
                <h5>{t(`DonationModal.${freq.toLowerCase()}`)}</h5>
              </div>
            ))}
        </div>

        {/* daily */}
        {howOften !== "ONETIME" && (
          <>
            <div className="how-often-div" style={{ padding: `${15}px` }}>
              <h5 className="how-often">
                #{" "}
                {charityDetails.donationCampaign ? (
                  <>{t("DonationModal.donations")}</>
                ) : (
                  <>{t("DonationModal.payments")}</>
                )}
                {howOften === "DAILY" &&
                  charityDetails?.endDate &&
                  charityDetails?.endDate !== "9999-12-31 00:00:00" &&
                  charityDetails?.frequency?.length === 1 &&
                  charityDetails.frequency.includes("DAILY") && (
                    <span
                      style={{
                        background: "#007c16",
                        color: "#fff",
                        padding: "4px",
                        marginLeft: "6px",
                        borderRadius: "2px",
                      }}
                    >
                      {recurringValue}
                    </span>
                  )}
              </h5>

              {!(
                howOften === "DAILY" &&
                charityDetails?.endDate &&
                charityDetails?.endDate !== "9999-12-31 00:00:00" &&
                charityDetails?.frequency?.length === 1 &&
                charityDetails.frequency.includes("DAILY")
              ) && (
                <>
                  <div className="radio-row">
                    <div className="radio-col">
                      <input
                        type="radio"
                        className="recurring-radio"
                        onChange={() => handleSpecifyTime(false)}
                        checked={!specifyTime}
                      />
                      {charityDetails.donationCampaign ? (
                        <>{t("DonationModal.make_this_recurring")}</>
                      ) : (
                        <>{t("DonationModal.make_this_recurring_payment")}</>
                      )}
                    </div>
                  </div>

                  <div className="radio-row">
                    <div className="radio-col">
                      <input
                        type="radio"
                        className="recurring-radio"
                        onChange={() => handleSpecifyTime(true)}
                        checked={specifyTime}
                      />
                      {specifyTime ? (
                        <>
                          {charityDetails.donationCampaign ? (
                            <>{t("DonationModal.i_want_to_donate")}</>
                          ) : (
                            <>{t("DonationModal.i_want_to_pay")}</>
                          )}

                          <input
                            type="number"
                            className="number-of-donations-input"
                            value={recurringValue}
                            onChange={(e) =>
                              handleRecurringValue(e.target.value)
                            }
                            onFocus={(e) => {
                              const input = e.target;
                              const preventScroll = (e) => e.preventDefault();
                              input.addEventListener("wheel", preventScroll, {
                                passive: false,
                              });

                              // Cleanup listener on blur
                              input.addEventListener(
                                "blur",
                                () => {
                                  input.removeEventListener(
                                    "wheel",
                                    preventScroll
                                  );
                                },
                                { once: true }
                              );
                            }}
                          />
                          {t("DonationModal.times")}
                          <div
                            className={`minimum-amount-msg${
                              validRecurring ? "" : " red"
                            }`}
                            style={{ marginLeft: `${7}px` }}
                          >
                            {t("DonationModal.minimum_recurring")}: 2
                          </div>
                        </>
                      ) : (
                        <>{t("DonationModal.i_want_to_specify")}</>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
            <MDBRow className="mb-3 mt-3" style={{ marginLeft: "3px" }}>
              <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
                <label
                  htmlFor="startDate"
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    textTransform: "uppercase",
                  }}
                >
                  {t("DonationModal.start_date")}
                  {howOften !== "ONETIME" && ` - ${howOften}`}
                  {howOften === "WEEKLY" &&
                    charityDetails.recurringDay &&
                    ` - ${
                      days.find(
                        (day) => day.value === charityDetails.recurringDay
                      )?.label || ""
                    }`}
                </label>

                <ReactDatePicker
                  style={{
                    width: 100 + "%",
                  }}
                  className="edit-media-inputs"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  dateFormat="yyyy-MM-dd"
                  placeholderText="Start Date"
                  required
                  minDate={new Date()}
                  filterDate={isSelectableDate}
                />
              </MDBCol>
            </MDBRow>
          </>
        )}

        {/* continue btn */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingBottom: `${25}px`,
          }}
        >
          <button
            type="button"
            className="continue-btn"
            onClick={handleContinue}
            style={{
              pointerEvents: amountValid && validRecurring ? "auto" : "none",
              opacity: amountValid && validRecurring ? 1 : 0.5,
            }}
          >
            {t("CampaignDetails.continue")}
            <span>
              <Icon icon={arrows_right} size={28} />
            </span>
          </button>
        </div>
      </div>

      {/* indicators */}
      <div className="indicators-box">
        <span
          className="indicators"
          onClick={() => {
            setStep(0);
            if (currentQuery) {
              navigate(`/${paths[0]}/${paths[1]}${currentQuery}`, {
                replace: true,
              });
            } else {
              navigate(`/${paths[0]}/${paths[1]}`, { replace: true });
            }
          }}
        />
        <span className="indicators" onClick={() => setStep(1)} />
        <span className="indicators active" />
        <span className="indicators" onClick={handleContinue} />
        {parseFloat(charityDetails.fees) > 0 && <span className="indicators" />}
      </div>

      {/* footer */}
      <div className="embedded-footer-box">
        <div>
          <span>
            <Icon icon={basic_lock} size={22} />
          </span>
          <span className="secure-donation">
            {t("OrganizationDetails.secure_donation")}
          </span>
        </div>

        <div className="powered-by">
          {t("Embed.powered_by")}
          <img src={logo} alt="logo" />
        </div>

        {lang === "en" ? (
          <button
            type="button"
            id="fr"
            onClick={(e) => handleLanguage(e.currentTarget.id)}
            className="change-lang-btn embed"
          >
            française
          </button>
        ) : (
          <button
            type="button"
            id="en"
            onClick={(e) => handleLanguage(e.currentTarget.id)}
            className="change-lang-btn embed"
          >
            English
          </button>
        )}
      </div>
    </div>
  );
};

export default EmbeddedHowOften;
