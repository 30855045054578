/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect } from "react";

// react router dom
import {
  Link,
  useLocation,
  useNavigate,
  NavLink,
  useSearchParams,
} from "react-router-dom";

// react redux
import { useSelector, useDispatch } from "react-redux";

// react icons kit
import Icon from "react-icons-kit";
import { basic_magnifier } from "react-icons-kit/linea/basic_magnifier";
import { menu } from "react-icons-kit/feather/menu";
import { basic_link } from "react-icons-kit/linea/basic_link";
import { ecommerce_receipt } from "react-icons-kit/linea/ecommerce_receipt";
import { userPlus } from "react-icons-kit/feather/userPlus";
import { logIn } from "react-icons-kit/feather/logIn";
import { basic_server_cloud } from "react-icons-kit/linea/basic_server_cloud";

// axios
import axios from "axios";

// clap spinner
import { ClapSpinner } from "react-spinners-kit";

// i18
import { useTranslation } from "react-i18next";
import i18next from "i18next";

// logo
import Tippy from "@tippyjs/react";
import logo from "../../images/header-logo.png";

// redux actions
import {
  getSearchData,
  getSearchDataLoggedIn,
} from "../../redux/searchOrganization/searchActions";

// packageFile
import packageFile from "../../../package.json";

// getting user data again b/c of null problem
function getUser() {
  let user = localStorage.getItem("user");
  if (user) {
    user = JSON.parse(user);
  } else {
    user = null;
  }
  return user;
}

const Header = ({
  hostName,
  userData,
  setLoggedInUserData,
  handleToggle,
  dropdownToggle,
  handleDropdownToggle,
  searchValue,
  setSearchValue,
  organizationPath,
  organizationDetails,
  charityPath,
  charityDetails,
  searchClass,
  setLinkDonationModal,
}) => {
  // getting user data again b/c of null problem
  const [userDataLS] = useState(getUser());

  // logout
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const handleLogout = () => {
    setLoader(true);
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${userData.token}`,
      },
    };
    axios
      .post(`${hostName}api/v1/auth/logout`, {}, axiosConfig)
      .then(() => {
        setLoader(false);
        localStorage.removeItem("user");
        setLoggedInUserData(null);
        navigate("/signin");
      })
      .catch((err) => {
        setLoader(false);
        if (err.response && err.response.status === 401) {
          localStorage.removeItem("user");
          setLoggedInUserData(null);
          navigate("/signin");
        }
      });
  };

  // getting data from searchReducer
  const searchResult = useSelector((state) => state.searchReducer);
  const searchLoader = searchResult.loader;
  const { searchArray } = searchResult;

  // links click
  const handleLinksClick = (e) => {
    if (loader || searchLoader) {
      e.preventDefault();
    }
  };

  // searching uncontrolled debounce
  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 2500);
    };
  };

  // searching uncontrolled
  const location = useLocation();
  const dispatch = useDispatch();
  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    if (location.pathname !== "/") {
      navigate("/");
      if (userDataLS) {
        dispatch(getSearchDataLoggedIn(e.target.value, userDataLS.email));
      } else {
        dispatch(getSearchData(e.target.value));
      }
    } else if (userDataLS) {
      dispatch(getSearchDataLoggedIn(e.target.value, userDataLS.email));
    } else {
      dispatch(getSearchData(e.target.value));
    }
  };

  // optimized search uncontrolled
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const optimizedVersion = useCallback(debounce(handleSearch), []);

  // is user dp loaded
  const [isLoaded, setIsLoaded] = useState(false);

  // set isLoaded to false again to retrack loading of the newly uploaded dp
  useEffect(() => {
    setIsLoaded(false);
  }, [userData?.profileImage]);

  // language code

  // lang state
  const [lang, setLang] = useState("");

  // search params
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    // Get language from query param
    const queryLang = searchParams.get("language");

    if (queryLang) {
      setLang(queryLang);
      i18next.changeLanguage(queryLang);
    } else {
      // If no query param, check localStorage
      const storedLang = localStorage.getItem("i18nextLng");
      if (storedLang) {
        setLang(storedLang);
        i18next.changeLanguage(storedLang.toString());
      } else {
        setLang("en");
        i18next.changeLanguage("en");
      }
    }
  }, [lang]);

  // handle language change on button click
  const handleLanguage = (id) => {
    setLang(id);
    i18next.changeLanguage(id);

    // Update query parameter dynamically
    searchParams.set("language", id);
    setSearchParams(searchParams);
  };

  // translation
  const { t } = useTranslation();

  // destructure version from package.json
  const { version } = packageFile;

  return (
    <div className="custom-header">
      <div className={!userData ? "top-nav wrap" : "top-nav"}>
        {/* toggler menu */}
        <button
          type="button"
          className="toggler"
          id="toggler"
          onClick={handleToggle}
          disabled={searchLoader || loader}
        >
          <Icon icon={menu} size={22} />
        </button>

        {/* logo and form */}
        <div className="logo-and-form">
          <div className="app-logo">
            <img src={logo} alt="logo" />
          </div>

          <div className={searchClass ? "search-form flash" : "search-form"}>
            {/* uncontrolled input */}
            <input
              type="text"
              required
              placeholder={t("Header.search_charity")}
              className="search-field"
              defaultValue={searchValue}
              onChange={optimizedVersion}
              readOnly={searchLoader || loader}
            />
            <button type="button" className="search-btn">
              <Icon icon={basic_magnifier} size={20} />
            </button>
          </div>
        </div>

        {/* dropdown or registeration links */}
        {userData ? (
          <div className="user-action-div">
            <div className="link-icons-div">
              <Tippy content={t("Header.download_tax_receipt")}>
                <span className="link-icons-span">
                  <NavLink to="/tax-receipts">
                    <Icon icon={ecommerce_receipt} size={20} />
                  </NavLink>
                </span>
              </Tippy>
              <Tippy content={t("Header.link_donation")}>
                <span
                  className="link-icons-span"
                  onClick={() => setLinkDonationModal(true)}
                >
                  <Icon icon={basic_link} size={20} />
                </span>
              </Tippy>
              <Tippy content={`Version: ${version}`}>
                <span className="link-icons-span">
                  <Icon icon={basic_server_cloud} size={20} />
                </span>
              </Tippy>
            </div>
            {lang === "en" ? (
              <button
                type="button"
                id="fr"
                onClick={(e) => handleLanguage(e.currentTarget.id)}
                className="change-lang-btn"
                disabled={searchLoader || loader}
              >
                française
              </button>
            ) : (
              <button
                type="button"
                id="en"
                onClick={(e) => handleLanguage(e.currentTarget.id)}
                className="change-lang-btn"
                disabled={searchLoader || loader}
              >
                English
              </button>
            )}
            <div
              role="button"
              className="user-profile"
              onClick={handleDropdownToggle}
              disabled={searchLoader || loader}
              style={{
                backgroundColor:
                  userData && userData.profileImage && isLoaded
                    ? "#f7f8fa"
                    : "#007c16",
                border: "1px solid #007c16",
              }}
            >
              {userData && !userData.profileImage ? (
                <>
                  {Array.from(userData.firstName)[0].toUpperCase()}
                  {Array.from(userData.lastName)[0].toUpperCase()}
                </>
              ) : (
                <>
                  {!isLoaded && <>...</>}
                  <img
                    src={userData.profileImage}
                    alt="dp"
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                      objectFit: "cover",
                      overflowClipMargin: "unset",
                      pointerEvents: "none",
                      display: !isLoaded ? "none" : "block",
                    }}
                    onLoad={() => setIsLoaded(true)}
                  />
                </>
              )}
            </div>
            <div
              className={
                dropdownToggle
                  ? "custom-profile-dropdown active"
                  : "custom-profile-dropdown"
              }
            >
              <h3 className="menu-name">{userData.firstName}</h3>
              <div className="menu-items-div">
                <div className="menu-items">
                  <NavLink
                    to="/profile"
                    className="menu-items-link"
                    onClick={handleLinksClick}
                  >
                    {t("Header.profile")}
                  </NavLink>
                </div>
                <div className="menu-items">
                  <NavLink
                    to="/tax-receipts"
                    className="menu-items-link"
                    onClick={handleLinksClick}
                  >
                    {t("Sidebar.taxReceipts")}
                  </NavLink>
                </div>
                <button
                  type="button"
                  className="tax-receipt"
                  onClick={() => setLinkDonationModal(true)}
                >
                  {t("Header.link_donation")}
                </button>
                <button
                  type="button"
                  className="menu-items logout"
                  onClick={handleLogout}
                  disabled={searchLoader || loader}
                >
                  <span className="logout-text">{t("Header.logout")}</span>
                  {loader && (
                    <ClapSpinner size={24} color="#007c16" loading={loader} />
                  )}
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="registeration-div">
            <Tippy content={t("Header.signup")}>
              <Link
                to="/signup"
                className="registeration-links"
                onClick={handleLinksClick}
              >
                <Icon icon={userPlus} size={20} />
              </Link>
            </Tippy>

            <Tippy content={t("Header.signin")}>
              <Link
                to="/signin"
                className="registeration-links"
                onClick={handleLinksClick}
              >
                <Icon icon={logIn} size={20} />
              </Link>
            </Tippy>

            <Tippy content={`Version: ${version}`}>
              <span style={{ cursor: "pointer" }}>
                <Icon icon={basic_server_cloud} size={20} />
              </span>
            </Tippy>

            {lang === "en" ? (
              <button
                type="button"
                id="fr"
                onClick={(e) => handleLanguage(e.currentTarget.id)}
                className="change-lang-btn"
                disabled={searchLoader || loader}
              >
                française
              </button>
            ) : (
              <button
                type="button"
                id="en"
                onClick={(e) => handleLanguage(e.currentTarget.id)}
                className="change-lang-btn"
                disabled={searchLoader || loader}
              >
                English
              </button>
            )}
          </div>
        )}
      </div>

      {/* navigation */}
      {searchArray && searchArray.length > 0 ? (
        <div className="navigation">
          <NavLink to="/" className="navigation-links">
            {t("Header.search_results")}
          </NavLink>
          {location.pathname === `/${organizationPath}` && (
            <>
              <div className="greater-than">&gt;</div>
              <Link
                to={`/${organizationPath}`}
                className="navigation-links active"
              >
                {organizationDetails.name}
              </Link>
            </>
          )}
          {location.pathname === `/${organizationPath}/${charityPath}` && (
            <>
              <div className="greater-than"> &gt; </div>
              <Link to={`/${organizationPath}`} className="navigation-links">
                {organizationDetails.name}
              </Link>
              <div className="greater-than"> &gt; </div>
              <Link
                to={`/${organizationPath}/${charityPath}`}
                className="navigation-links active"
              >
                {charityDetails.name}
              </Link>
            </>
          )}
        </div>
      ) : (
        <>
          {userData && (
            <div className="navigation">
              <NavLink to="/" className="navigation-links">
                {t("Header.my_charities")}
              </NavLink>
              {location.pathname === `/${organizationPath}` && (
                <>
                  <div className="greater-than">&gt;</div>
                  <Link
                    to={`/${organizationPath}`}
                    className="navigation-links active"
                  >
                    {organizationDetails.name}
                  </Link>
                </>
              )}
              {location.pathname === `/${organizationPath}/${charityPath}` && (
                <>
                  <div className="greater-than"> &gt; </div>
                  <Link
                    to={`/${organizationPath}`}
                    className="navigation-links"
                  >
                    {organizationDetails.name}
                  </Link>
                  <div className="greater-than"> &gt; </div>
                  <Link
                    to={`/${organizationPath}/${charityPath}`}
                    className="navigation-links active"
                  >
                    {charityDetails.name}
                  </Link>
                </>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Header;
