/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ClapSpinner } from "react-spinners-kit";
import Header from "../../components/common/Header";
import Sidebar from "../../components/common/Sidebar";
import EmbeddedOrganization from "../../components/embed/EmbeddedOrganization";
import Charity from "../search/Charity";
import Organization from "../search/Organization";

// getting user data again b/c of null problem
function getUserData() {
  let user = localStorage.getItem("user");
  if (user) {
    user = JSON.parse(user);
  } else {
    user = null;
  }
  return user;
}

const NotFound = ({
  hostName,
  userData,
  setLoggedInUserData,
  handleToggle,
  toggled,
  dropdownToggle,
  handleDropdownToggle,
  searchValue,
  setSearchValue,
  organizationPath,
  setOrganizationPath,
  organizationDetails,
  setOrganizationDetails,
  charityPath,
  setCharityPath,
  charityDetails,
  setCharityDetails,
  donationAmount,
  setDonationAmount,
  amountsWithoutZero,
  donationModal,
  setDonationModal,
  setTaxReceiptModal,
  setLinkDonationModal,
  paths,
  vTag,
  currentQuery,
  quantity,
  setQuantity,
}) => {
  // getting user data again b/c of null problem
  const [userDataLS] = useState(getUserData());

  // step
  const [step, setStep] = useState(0);

  // navigate
  const navigate = useNavigate();

  // remove extra segments
  useEffect(() => {
    if (paths[0] === "embedded" && paths.length > 3) {
      if (currentQuery) {
        navigate(`/${paths[0]}/${paths[1]}/${paths[2]}${currentQuery}`, {
          replace: true,
        });
      } else {
        navigate(`/${paths[0]}/${paths[1]}/${paths[2]}`, {
          replace: true,
        });
      }
    } else if (paths[0] !== "embedded" && paths.length > 2) {
      if (currentQuery) {
        navigate(`/${paths[0]}/${paths[1]}${currentQuery}`, { replace: true });
      } else {
        navigate(`/${paths[0]}/${paths[1]}`, { replace: true });
      }
    }
  }, [paths, currentQuery]);

  // fetching organization
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    if (paths[0] === "embedded" && paths[1]) {
      setLoader(true);
      // if user is logged out
      if (!userDataLS) {
        axios
          .get(`${hostName}api/v1/search/organization`, {
            params: {
              name: paths[1],
              type: "shortName",
            },
            // headers: {
            //   "X-Local-Access-Token":
            //     "zrPIG1Q90I0cfPhmBZuH1hQErA1mUvQG7mE4jk3ta8lqBJw1L15TGvqJD5MMxwAO",
            // },
          })
          .then((res) => {
            if (res.data.data && res.data.data.length > 0) {
              // setting states
              setLoader(false);
              setError(null);
              // local storage
              localStorage.setItem(
                "organization",
                JSON.stringify(res.data.data[0])
              );
              // setting props
              setOrganizationDetails(res.data.data[0]);
              // setting organization path
              const data = res.data.data[0];
              const organizationName = data.shortName;
              const cleanOrganizationName = organizationName.replace(
                /[^a-zA-Z0-9]/g,
                ""
              );
              setOrganizationPath(cleanOrganizationName.toLowerCase());
              // Fetching campaign for the given organization if the path[2] exists
              const matchingCampaign = data.campaigns.find((campaign) => {
                const word = campaign.name.replace(/[^a-zA-Z0-9]/g, "");
                const lowercase = word.toLowerCase();
                return paths[2] && paths[2] === lowercase;
              });

              if (matchingCampaign) {
                const lowercase = matchingCampaign.name
                  .replace(/[^a-zA-Z0-9]/g, "")
                  .toLowerCase();
                setCharityPath(lowercase);
                setCharityDetails(matchingCampaign);
                setStep(1);
                localStorage.setItem(
                  "campaign",
                  JSON.stringify(matchingCampaign)
                );
              } else {
                setCharityPath(null);
                setCharityDetails(null);
                localStorage.removeItem("campaign");
              }
            } else {
              // setting states
              setLoader(false);
              setError("No Organization Found.");
              // setting local storage
              localStorage.removeItem("organization");
              // setting props
              setOrganizationDetails(null);
              setOrganizationPath(null);
            }
          })
          .catch((err) => {
            // setting states
            setLoader(false);
            setError(err.message);
            // setting local storage
            localStorage.removeItem("organization");
            // setting props
            setOrganizationDetails(null);
            setOrganizationPath(null);
          });
      } else {
        axios
          .get(`${hostName}api/v1/search/organization`, {
            params: {
              name: paths[1],
              type: "shortName",
              email: userDataLS.email,
            },
          })
          .then((res) => {
            if (res.data.data && res.data.data.length > 0) {
              // setting states
              setLoader(false);
              setError(null);
              // local storage
              localStorage.setItem(
                "organization",
                JSON.stringify(res.data.data[0])
              );
              // setting props
              setOrganizationDetails(res.data.data[0]);
              // setting organization path
              const data = res.data.data[0];
              const organizationName = data.shortName;
              const cleanOrganizationName = organizationName.replace(
                /[^a-zA-Z0-9]/g,
                ""
              );
              setOrganizationPath(cleanOrganizationName.toLowerCase());
              // Fetching campaign for the given organization if the path[2] exists
              const matchingCampaign = data.campaigns.find((campaign) => {
                const word = campaign.name.replace(/[^a-zA-Z0-9]/g, "");
                const lowercase = word.toLowerCase();
                return paths[2] && paths[2] === lowercase;
              });

              if (matchingCampaign) {
                const lowercase = matchingCampaign.name
                  .replace(/[^a-zA-Z0-9]/g, "")
                  .toLowerCase();
                setCharityPath(lowercase);
                setCharityDetails(matchingCampaign);
                setStep(1);
                localStorage.setItem(
                  "campaign",
                  JSON.stringify(matchingCampaign)
                );
              } else {
                setCharityPath(null);
                setCharityDetails(null);
                localStorage.removeItem("campaign");
              }
            } else {
              // setting states
              setLoader(false);
              setError("No Organization Found.");
              // setting local storage
              localStorage.removeItem("organization");
              // setting props
              setOrganizationDetails(null);
              setOrganizationPath(null);
            }
          })
          .catch((err) => {
            // setting states
            setLoader(false);
            setError(err.message);
            // setting local storage
            localStorage.removeItem("organization");
            // setting props
            setOrganizationDetails(null);
            setOrganizationPath(null);
          });
      }
    } else {
      setLoader(true);
      // if user is logged out
      if (!userDataLS) {
        axios
          .get(
            `${hostName}api/v1/search/organization?name=${paths[0]}&type=shortName`,
            {
              // headers: {
              //   "X-Local-Access-Token":
              //     "zrPIG1Q90I0cfPhmBZuH1hQErA1mUvQG7mE4jk3ta8lqBJw1L15TGvqJD5MMxwAO",
              // },
            }
          )
          .then((res) => {
            if (res.data.data && res.data.data.length > 0) {
              // setting states
              setLoader(false);
              setError(null);
              // local storage
              localStorage.setItem(
                "organization",
                JSON.stringify(res.data.data[0])
              );
              // setting props
              setOrganizationDetails(res.data.data[0]);
              // setting organization path
              const data = res.data.data[0];
              const organizationName = data.shortName;
              const cleanOrganizationName = organizationName.replace(
                /[^a-zA-Z0-9]/g,
                ""
              );
              setOrganizationPath(cleanOrganizationName.toLowerCase());
              // Fetching campaign for the given organization if the path[1] exists
              const matchingCampaign = data.campaigns.find((campaign) => {
                const word = campaign.name.replace(/[^a-zA-Z0-9]/g, "");
                const lowercase = word.toLowerCase();
                return paths[1] && paths[1] === lowercase;
              });

              if (matchingCampaign) {
                const lowercase = matchingCampaign.name
                  .replace(/[^a-zA-Z0-9]/g, "")
                  .toLowerCase();
                setCharityPath(lowercase);
                setCharityDetails(matchingCampaign);
                localStorage.setItem(
                  "campaign",
                  JSON.stringify(matchingCampaign)
                );
              } else {
                setCharityPath(null);
                setCharityDetails(null);
                localStorage.removeItem("campaign");
                setError("Invalid Campaign.");
              }
            } else {
              // setting states
              setLoader(false);
              setError("No Organization Found.");
              // setting local storage
              localStorage.removeItem("organization");
              // setting props
              setOrganizationDetails(null);
              setOrganizationPath(null);
            }
          })
          .catch((err) => {
            // setting states
            setLoader(false);
            setError(err.message);
            // setting local storage
            localStorage.removeItem("organization");
            // setting props
            setOrganizationDetails(null);
            setOrganizationPath(null);
          });
      } else {
        axios
          .get(
            `${hostName}api/v1/search/organization?name=${paths[0]}&type=shortName&email=${userDataLS.email}`
          )
          .then((res) => {
            if (res.data.data && res.data.data.length > 0) {
              // setting states
              setLoader(false);
              setError(null);
              // local storage
              localStorage.setItem(
                "organization",
                JSON.stringify(res.data.data[0])
              );
              // setting props
              setOrganizationDetails(res.data.data[0]);
              // setting organization path
              const data = res.data.data[0];
              const organizationName = data.shortName;
              const cleanOrganizationName = organizationName.replace(
                /[^a-zA-Z0-9]/g,
                ""
              );
              setOrganizationPath(cleanOrganizationName.toLowerCase());
              // Fetching campaign for the given organization if the path[1] exists
              const matchingCampaign = data.campaigns.find((campaign) => {
                const word = campaign.name.replace(/[^a-zA-Z0-9]/g, "");
                const lowercase = word.toLowerCase();
                return paths[1] && paths[1] === lowercase;
              });

              if (matchingCampaign) {
                const lowercase = matchingCampaign.name
                  .replace(/[^a-zA-Z0-9]/g, "")
                  .toLowerCase();
                setCharityPath(lowercase);
                setCharityDetails(matchingCampaign);
                localStorage.setItem(
                  "campaign",
                  JSON.stringify(matchingCampaign)
                );
              } else {
                setCharityPath(null);
                setCharityDetails(null);
                localStorage.removeItem("campaign");
                setError("Invalid Campaign.");
              }
            } else {
              // setting states
              setLoader(false);
              setError("No Organization Found.");
              // setting local storage
              localStorage.removeItem("organization");
              // setting props
              setOrganizationDetails(null);
              setOrganizationPath(null);
            }
          })
          .catch((err) => {
            // setting states
            setLoader(false);
            setError(err.message);
            // setting local storage
            localStorage.removeItem("organization");
            // setting props
            setOrganizationDetails(null);
            setOrganizationPath(null);
          });
      }
    }
  }, []);

  return (
    <>
      {loader ? (
        <div className="loading-screen">
          <ClapSpinner size={30} color="#007c16" loading={loader} />
        </div>
      ) : (
        <>
          {error ? (
            <>
              {paths[0] === "embedded" ? (
                <div
                  className={`main-container ${userData ? "logged-in" : ""}`}
                >
                  <main
                    className="info-content-div"
                    style={{ height: `${100}%` }}
                  >
                    <div className="error-msg" style={{ marginTop: 0 }}>
                      {error}
                    </div>
                  </main>
                </div>
              ) : (
                <div
                  className={`main-container ${userData ? "logged-in" : ""}`}
                >
                  <Header
                    hostName={hostName}
                    userData={userData}
                    setLoggedInUserData={setLoggedInUserData}
                    handleToggle={handleToggle}
                    dropdownToggle={dropdownToggle}
                    handleDropdownToggle={handleDropdownToggle}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    organizationPath={organizationPath}
                    organizationDetails={organizationDetails}
                    setTaxReceiptModal={setTaxReceiptModal}
                    setLinkDonationModal={setLinkDonationModal}
                  />
                  <div className="dashboard-container">
                    <Sidebar userData={userData} toggled={toggled} />
                    <div className="home-content">
                      <main
                        className="info-content-div"
                        style={{ height: `${100}%` }}
                      >
                        <div className="error-msg" style={{ marginTop: 0 }}>
                          {error}
                        </div>
                      </main>
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              {paths[0] === "embedded" ? (
                <>
                  <EmbeddedOrganization
                    hostName={hostName}
                    userData={userData}
                    organizationDetails={organizationDetails}
                    charityDetails={charityDetails}
                    setCharityDetails={setCharityDetails}
                    step={step}
                    setStep={setStep}
                    paths={paths}
                    vTag={vTag}
                    currentQuery={currentQuery}
                    quantity={quantity}
                    setQuantity={setQuantity}
                    amountsWithoutZero={amountsWithoutZero}
                  />
                </>
              ) : (
                <>
                  {!paths[1] ? (
                    <Organization
                      hostName={hostName}
                      userData={userData}
                      setLoggedInUserData={setLoggedInUserData}
                      toggled={toggled}
                      handleToggle={handleToggle}
                      dropdownToggle={dropdownToggle}
                      handleDropdownToggle={handleDropdownToggle}
                      searchValue={searchValue}
                      setSearchValue={setSearchValue}
                      organizationPath={organizationPath}
                      organizationDetails={organizationDetails}
                      setCharityPath={setCharityPath}
                      setCharityDetails={setCharityDetails}
                      setTaxReceiptModal={setTaxReceiptModal}
                      setLinkDonationModal={setLinkDonationModal}
                      currentQuery={currentQuery}
                      setQuantity={setQuantity}
                    />
                  ) : (
                    <>
                      <Charity
                        hostName={hostName}
                        userData={userData}
                        setLoggedInUserData={setLoggedInUserData}
                        toggled={toggled}
                        handleToggle={handleToggle}
                        dropdownToggle={dropdownToggle}
                        handleDropdownToggle={handleDropdownToggle}
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                        organizationPath={organizationPath}
                        organizationDetails={organizationDetails}
                        charityPath={charityPath}
                        charityDetails={charityDetails}
                        donationAmount={donationAmount}
                        setDonationAmount={setDonationAmount}
                        amountsWithoutZero={amountsWithoutZero}
                        donationModal={donationModal}
                        setDonationModal={setDonationModal}
                        setTaxReceiptModal={setTaxReceiptModal}
                        setLinkDonationModal={setLinkDonationModal}
                        vTag={vTag}
                        currentQuery={currentQuery}
                        quantity={quantity}
                        setQuantity={setQuantity}
                      />
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default NotFound;
