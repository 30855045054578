/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { MDBBtn, MDBCol, MDBRow } from "mdb-react-ui-kit";
import axios from "axios";
import { ClapSpinner } from "react-spinners-kit";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { styled } from "@mui/material/styles";
import RegisterDonor from "./RegisterDonor";
import sparks from "../../images/sparks.png";
import BigNumber from "bignumber.js";
import ReactDatePicker from "react-datepicker";
import Confetti from "react-confetti";

// custom styles
const styles = {
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007c16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007c16" : "none",
    "&:hover": {
      border: "1px solid #007c16",
      boxShadow: "0px 0px 1px #007c16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007c16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007c16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

// custom textfield / amount
const CustomTextField = styled(TextField)`
  & label.Mui-focused {
    color: #007c16;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border: 1px solid;
      border-color: #007c16;
    }
  }
  &:hover {
    & label {
      color: #007c16;
    }
    & .MuiOutlinedInput-root {
      fieldset {
        border-color: #007c16;
      }
    }
  }
`;

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// Helper function to get the first future date that matches the recurringDay
const getNextMatchingDay = (recurringDay) => {
  const currentDate = new Date();
  const currentDayOfWeek = currentDate.getDay(); // getDay() gives 0-6 (0 is Sunday, 6 is Saturday)

  // Adjust recurringDay to match 0-6 range, where 1 is Monday and 7 is Sunday
  const adjustedRecurringDay = recurringDay % 7; // Recurring day (1-7), convert 7 to 0 for Sunday

  // If the current day is the same or before the recurring day, select this week's day
  if (adjustedRecurringDay >= currentDayOfWeek) {
    currentDate.setDate(
      currentDate.getDate() + (adjustedRecurringDay - currentDayOfWeek)
    );
  } else {
    // If the recurring day is earlier in the week, select the next week's matching day
    currentDate.setDate(
      currentDate.getDate() + (7 - (currentDayOfWeek - adjustedRecurringDay))
    );
  }

  return currentDate;
};

const DonationModal = ({
  hostName,
  userData,
  setLoggedInUserData,
  organizationDetails,
  charityDetails,
  donationAmount,
  setDonationAmount,
  amountsWithoutZero,
  setDonationModal,
  vTag,
  quantity,
}) => {
  // Function to generate the month options
  const generateMonthOptions = () => {
    const monthOptions = [];

    for (let i = 1; i <= 12; i++) {
      const monthValue = i < 10 ? `0${i}` : `${i}`;
      monthOptions.push({
        value: monthValue,
        label: monthValue,
      });
    }

    return monthOptions;
  };

  // Generate the month options
  const expiryMonthOptions = generateMonthOptions();

  // Get the current year
  const currentYear = new Date().getFullYear();

  // Function to generate the expiry year options
  const generateExpiryYearOptions = (startYear, numberOfYears) => {
    const expiryYearOptions = [];

    for (let i = 0; i < numberOfYears; i++) {
      const year = startYear + i;
      expiryYearOptions.push({
        value: String(year).slice(-2),
        label: String(year),
      });
    }

    return expiryYearOptions;
  };

  // Generate the expiry year options for the next 15 years
  const expiryYearOptions = generateExpiryYearOptions(currentYear, 15);

  // states
  const [newAmount, setNewAmount] = useState(
    charityDetails.enableQuantity
      ? parseInt(quantity) * parseFloat(charityDetails.minimumAmount)
      : parseFloat(donationAmount)
  );

  const [howOften, setHowOften] = useState("");
  useEffect(() => {
    if (charityDetails?.frequency?.length > 0) {
      if (charityDetails.frequency.includes("ONETIME")) {
        setHowOften("ONETIME");
        setRecurringValue(null);
      } else {
        setHowOften(charityDetails.frequency[0]);
        setRecurringValue(1);
      }
    }
  }, [charityDetails]);
  const [recurringValue, setRecurringValue] = useState(
    howOften === "ONETIME" ? null : 1
  );
  const [validRecurring, setValidRecurring] = useState(true);
  const [specifyTime, setSpecifyTime] = useState(false);
  const [fees, setFees] = useState(0.0);
  const [isChecked, setIsChecked] = useState(false);
  const [startDate, setStartDate] = useState(new Date());

  // useEffect to update startDate when charityDetails and howOften become available
  useEffect(() => {
    if (howOften === "WEEKLY" && charityDetails?.recurringDay) {
      const nextMatchingDate = getNextMatchingDay(charityDetails.recurringDay);
      setStartDate(nextMatchingDate);
    } else {
      setStartDate(new Date());
    }
  }, [charityDetails, howOften]);

  // hiding the past dates
  const isSelectableDate = (date) => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); // Set the time to midnight for accurate comparison

    // If the user has selected WEEKLY and recurringDay is provided
    if (howOften === "WEEKLY" && charityDetails.recurringDay) {
      // Get the weekday of the selected date (0 for Sunday, 6 for Saturday)
      const selectedWeekday = date.getDay() === 0 ? 7 : date.getDay(); // Adjust for Sunday being 0

      // Only allow future dates that match the recurringDay
      return (
        date >= currentDate && selectedWeekday === charityDetails.recurringDay
      );
    }

    // prevent selection beyond endDate
    if (
      howOften === "DAILY" &&
      charityDetails?.endDate &&
      charityDetails?.endDate !== "9999-12-31 00:00:00" &&
      charityDetails?.frequency?.length === 1 &&
      charityDetails.frequency.includes("DAILY")
    ) {
      const endDate = new Date(charityDetails.endDate); // Convert endDate to Date object
      endDate.setHours(23, 59, 59, 999); // Ensure the entire day is included

      return date >= currentDate && date <= endDate;
    }

    return date >= currentDate;
  };

  // Calculate the number of days between the date selected on calendar (startDate) and the end date of campaign.
  const [noOfDays, setNoOfDays] = useState(null);

  useEffect(() => {
    if (noOfDays) {
      setHowOften("DAILY");
      setRecurringValue(noOfDays);
      setValidRecurring(true);
      setSpecifyTime(false);
    }
  }, [noOfDays]);

  useEffect(() => {
    if (
      startDate &&
      charityDetails?.endDate &&
      charityDetails?.endDate !== "9999-12-31 00:00:00" &&
      howOften === "DAILY" &&
      charityDetails?.frequency?.length === 1 &&
      charityDetails.frequency.includes("DAILY")
    ) {
      const start = new Date(startDate);
      const end = new Date(charityDetails.endDate);

      // Remove the time component
      start.setHours(0, 0, 0, 0);
      end.setHours(0, 0, 0, 0);

      if (!isNaN(end)) {
        const diffInMs = end - start;
        const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24)) + 1; // Add 1 to include both dates
        setNoOfDays(diffInDays);
      } else {
        console.error("Invalid endDate format");
      }
    }
  }, [startDate, howOften, charityDetails?.endDate, charityDetails?.frequency]);

  // checkbox
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  // new amount and fees
  useEffect(() => {
    if (!charityDetails.enableQuantity) {
      const feesExist = parseFloat(charityDetails.fees) > 0;

      if (feesExist && isChecked) {
        const feeAmount = new BigNumber(parseFloat(charityDetails.fees))
          .dividedBy(100)
          .times(parseFloat(donationAmount))
          .toFixed(2);

        const updatedAmount = new BigNumber(parseFloat(donationAmount))
          .plus(feeAmount)
          .toFixed(2);

        setFees(feeAmount);
        setNewAmount(updatedAmount);
      } else {
        setNewAmount(donationAmount);
        setFees(0);
      }
    }
  }, [isChecked, donationAmount, charityDetails?.enableQuantity]);

  // handle how often buttons
  const handleHowOften = (text) => {
    setHowOften(text);

    if (
      text === "DAILY" &&
      charityDetails?.endDate &&
      charityDetails?.endDate !== "9999-12-31 00:00:00" &&
      charityDetails?.frequency?.length === 1 &&
      charityDetails.frequency.includes("DAILY")
    ) {
      setNoOfDays(null);
      return; // Stop execution, so updateRecurringValue does not run
    }

    updateRecurringValue(text, specifyTime);
  };

  // handle donation amount
  const handleDonationAmount = (value) => {
    if (value) {
      setDonationAmount(value);
    } else {
      setDonationAmount("");
    }
  };

  // Function to handle recurring value updates without useEffect
  const updateRecurringValue = (howOften, specifyTime) => {
    let noOfFrequency = howOften === "ONETIME" ? null : 1;

    if (howOften === "DAILY" && specifyTime) {
      noOfFrequency = 30;
    } else if (howOften === "WEEKLY" && specifyTime) {
      noOfFrequency = 52;
    } else if (howOften === "BIWEEKLY" && specifyTime) {
      noOfFrequency = 26;
    } else if (howOften === "MONTHLY" && specifyTime) {
      noOfFrequency = 12;
    } else if (howOften === "ONETIME") {
      noOfFrequency = null;
      setStartDate(new Date());
    }

    setRecurringValue(noOfFrequency);
    setValidRecurring(true);
  };

  // radio change
  const handleSpecifyTime = (boolean) => {
    setSpecifyTime(boolean);
    updateRecurringValue(howOften, boolean);
  };

  // handle input box event for no of recurrings
  const handleRecurringValue = (value) => {
    let newValue = parseInt(value);
    if ((newValue < 2 || isNaN(newValue)) && specifyTime) {
      setValidRecurring(false);
    } else if (newValue >= 2 && specifyTime) {
      setValidRecurring(true);
    }
    if (newValue < 0) {
      newValue = 0;
    }
    setRecurringValue(newValue);
  };

  // step
  const [step, setStep] = useState(charityDetails.enableQuantity ? 3 : 1);

  // handleGoToCardStep
  const handleGoToCardStep = () => {
    if (
      validRecurring &&
      parseFloat(donationAmount) >= parseFloat(charityDetails.minimumAmount)
    ) {
      if (parseFloat(charityDetails.fees) > 0) {
        setStep(step + 1);
      } else {
        setStep(step + 2);
      }
    }
  };

  // donation event
  const [cardHolderName, setCardHolderName] = useState("");
  const [cardHolderEmail, setCardHolderEmail] = useState("");
  useEffect(() => {
    if (userData) {
      setCardHolderEmail(userData.email);
    }
  }, [userData]);
  const [cardNumber, setCardNumber] = useState("");
  const [expiryMonth, setExpiryMonth] = useState("");
  const [expiryYear, setExpiryYear] = useState("");
  const [cvc, setCvc] = useState("");
  const [nodeTag, setNodeTag] = useState("");
  const [campaignTag, setCampaignTag] = useState("");
  useEffect(() => {
    setNodeTag(organizationDetails.node.tagNumber);
    setCampaignTag(charityDetails.tagNumber);
  }, [organizationDetails]);
  const [transactionError, setTransactionError] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);
  const [loader, setLoader] = useState(false);

  // retry count
  const [retryCount, setRetryCount] = useState(0);

  // option states
  const [countries, setCountries] = useState([]);
  const [usaStates, setUsaStates] = useState([]);
  const [provinces, setProvinces] = useState([]);

  // Common function to load and initialize reCAPTCHA
  const loadAndInitializeRecaptcha = () => {
    // Retry loading reCAPTCHA script on button click
    const retryLoadReCaptchaScript = () => {
      if (retryCount < 3) {
        setRetryCount((prevCount) => prevCount + 1);
        loadReCaptchaScript();
      } else {
        setLoader(false);
        setTransactionError(
          "Unable to load reCAPTCHA after multiple retries. Please try again later."
        );
      }
    };

    // Load reCAPTCHA script
    const loadReCaptchaScript = () => {
      const script = document.createElement("script");
      script.src = "https://www.google.com/recaptcha/api.js";
      script.async = true;
      script.defer = true;
      script.onload = initializeRecaptcha;
      document.head.appendChild(script);
    };

    // Initialize reCAPTCHA
    const initializeRecaptcha = () => {
      console.log("reCAPTCHA script loaded");
      console.log("window.grecaptcha:", window.grecaptcha);
      console.log(
        "typeof window.grecaptcha.execute:",
        typeof window.grecaptcha.execute
      );
      if (
        window.grecaptcha &&
        typeof window.grecaptcha.execute === "function"
      ) {
        window.grecaptcha
          .execute("6LelmPUjAAAAAFA5Ks_St3J5yxjK4Jce7VBKsJgW", {
            action: "CHECKOUT",
          })
          .then((token) => {
            if (token) {
              // Process payment based on the type (onetime or recurring)
              const paymentData =
                howOften === "ONETIME"
                  ? {
                      frequency: "once",
                      numbers: 1,
                    }
                  : {
                      billingPeriod: howOften,
                      noOfRecurring: recurringValue,
                      startDate: formatDate(startDate),
                    };

              axios
                .post(
                  howOften === "ONETIME"
                    ? `${hostName}api/v1/transaction/byRawCard`
                    : `${hostName}api/v1/transaction/recurringByCard`,
                  {
                    ...paymentData,
                    campaignTag,
                    amount: parseFloat(newAmount),
                    nodeTag,
                    cardHolderName,
                    cardNumber,
                    expiryMonth,
                    expiryYear,
                    ccv: cvc,
                    email: cardHolderEmail,
                    fee: parseFloat(fees),
                    recaptchaToken: token,
                    ...(vTag && { vTag }),
                  }
                )
                .then((res) => {
                  setSuccessMsg(res.data.message);
                  setStep(step + 1); // 3+1=4
                })
                .catch((err) => {
                  if (err.response.data.message) {
                    setTransactionError(err.response.data.message);
                  } else {
                    setTransactionError(err.message);
                  }
                })
                .finally(() => setLoader(false));
            } else {
              setLoader(false);
              setTransactionError(
                "No reCAPTCHA token found. Please try again later."
              );
            }
          })
          .catch((err) => {
            setLoader(false);
            setTransactionError(
              `${err.message} in executing reCAPTCHA. Please try again later.`
            );
          });
      } else {
        retryLoadReCaptchaScript();
      }
    };

    // Check if grecaptcha is already loaded, if not, load the script
    if (window.grecaptcha) {
      initializeRecaptcha();
    } else {
      loadReCaptchaScript();
    }
  };

  // Handle donate
  const handleDonate = (e) => {
    e.preventDefault();
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (emailRegex.test(cardHolderEmail)) {
      if (organizationDetails.forceRegistration === "Y") {
        setLoader(true);
        setTransactionError(null);
        // Check if email exists
        axios
          .get(`${hostName}api/v1/auth/exist?email=${cardHolderEmail}`)
          .then((res) => {
            if (res.data.exist) {
              loadAndInitializeRecaptcha();
            } else {
              // Handle registration options
              Promise.all([
                axios.get(`${hostName}api/v1/public/country`),
                axios.get(`${hostName}api/v1/public/state?countryCode=US`),
                axios.get(`${hostName}api/v1/public/state?countryCode=ca`),
              ])
                .then(([countriesRes, usaStatesRes, provincesRes]) => {
                  const countryOptions = countriesRes.data.map((country) => ({
                    value: country.code,
                    label: country.name,
                  }));
                  setCountries(countryOptions);

                  const usaStatesOptions = usaStatesRes.data.map((state) => ({
                    value: state.code,
                    label: state.name,
                  }));
                  setUsaStates(usaStatesOptions);

                  const provincesOptions = provincesRes.data.map(
                    (province) => ({
                      value: province.code,
                      label: province.name,
                    })
                  );
                  setProvinces(provincesOptions);

                  setStep("register");
                })
                .catch((error) => {
                  setTransactionError(
                    `${error.message} in fetching countries block of code.`
                  );
                })
                .finally(() => setLoader(false));
            }
          })
          .catch((err) => {
            setLoader(false);
            setTransactionError(
              `${err.message} in verifying email existence. Please try again later.`
            );
          });
      } else if (organizationDetails.forceRegistration === "N") {
        setLoader(true);
        setTransactionError(null);
        loadAndInitializeRecaptcha();
      } else {
        console.log("force registration attribute not available");
      }
    } else {
      setTransactionError("Email field should be a valid email address.");
    }
  };

  // format date
  const formatDate = (dateObj) => {
    if (dateObj) {
      const year = dateObj.getFullYear().toString();
      const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
      const day = dateObj.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    }
  };

  // day options
  const days = [
    { value: 1, label: "MONDAY" },
    { value: 2, label: "TUESDAY" },
    { value: 3, label: "WEDNESDAY" },
    { value: 4, label: "THURSDAY" },
    { value: 5, label: "FRIDAY" },
    { value: 6, label: "SATURDAY" },
    { value: 7, label: "SUNDAY" },
  ];

  // translation
  const { t } = useTranslation();

  // box reference
  const box = useRef(null);

  // confetti dimensions
  const [confettiWidth, setConfettiWidth] = useState(0);
  const [confettiHeight, setConfettiHeight] = useState(0);

  // is confetti
  const [isConfetti, setIsConfetti] = useState(false);

  // update is confetti
  useEffect(() => {
    if (step === 4 && successMsg !== null && charityDetails?.donationCampaign) {
      setIsConfetti(true);

      setTimeout(() => {
        setIsConfetti(false);
      }, 7000);
    } else {
      setIsConfetti(false);
    }
  }, [step, successMsg, charityDetails]);

  // update dimensions
  useEffect(() => {
    if (box && box.current) {
      setConfettiWidth(box.current.offsetWidth);
      setConfettiHeight(box.current.offsetHeight);
    }
  }, [box]);

  return (
    <AnimatePresence>
      <motion.div
        className={`backdrop${step === "register" ? " registeration" : ""}`}
        variants={backVariants}
        initial="hidden"
        animate="visible"
        ref={box}
      >
        {step === 4 &&
          successMsg !== null &&
          isConfetti &&
          charityDetails.donationCampaign && (
            <Confetti
              width={confettiWidth}
              height={confettiHeight}
              numberOfPieces={2000}
              recycle={false}
            />
          )}

        <motion.div
          className={`${
            step === "register"
              ? "registeration-modal"
              : charityDetails.donationCampaign
              ? "donation-modal"
              : "payment-modal"
          }`}
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          {step !== "register" ? (
            <div className="donate-box">
              {loader && (
                <div className="loading-screen">
                  <ClapSpinner size={30} color="#007c16" loading={loader} />
                </div>
              )}

              <button
                type="button"
                className="cross-icon donations"
                onClick={() => setDonationModal(false)}
                disabled={loader}
              >
                X
              </button>

              <header className="modal-header">
                <h3>{charityDetails.name}</h3>
                <div>{organizationDetails.name}</div>
              </header>

              {/* first screen */}
              {step === 1 && !charityDetails.enableQuantity && (
                <>
                  <span
                    className="donation-text"
                    style={{ textTransform: "uppercase" }}
                  >
                    {t("DonationModal.amount")}
                    <span
                      className="minimum-amount-msg"
                      style={{
                        marginLeft: 10 + "px",
                        textTransform: "capitalize",
                      }}
                    >
                      {t("DonationModal.minimum_amount")}{" "}
                      {organizationDetails.currencySymbol}
                      {charityDetails.minimumAmount}
                    </span>
                  </span>

                  {charityDetails?.amounts.includes(0) ? (
                    <Autocomplete
                      fullWidth
                      freeSolo
                      options={amountsWithoutZero.map((amount) =>
                        amount.toString()
                      )}
                      getOptionLabel={(option) => option.toString()}
                      value={donationAmount}
                      onChange={(e, value) => {
                        if (isNaN(value) || parseFloat(value) < 0) {
                          value = "0";
                        }
                        handleDonationAmount(value);
                      }}
                      onKeyDown={(e) => {
                        const parts = e.target.value.split(".");

                        // Allow numeric keys, Backspace, Delete, navigation keys, and decimal point
                        if (
                          !/[0-9]/.test(e.key) &&
                          ![
                            "Backspace",
                            "Delete",
                            "ArrowLeft",
                            "ArrowRight",
                            "ArrowUp",
                            "ArrowDown",
                            ".",
                          ].includes(e.key)
                        ) {
                          e.preventDefault();
                        }
                        // Prevent typing more than two decimal places
                        else if (
                          e.key !== "Backspace" &&
                          parts.length > 1 &&
                          parts[1].length >= 2 &&
                          /[0-9]/.test(e.key)
                        ) {
                          e.preventDefault();
                        }
                        // Prevent typing second decimal point
                        else if (
                          e.key === "." &&
                          e.target.value.includes(".")
                        ) {
                          e.preventDefault();
                        }
                      }}
                      renderOption={(props, option) => {
                        return (
                          <div {...props} className="list-div">
                            {option}
                          </div>
                        );
                      }}
                      renderInput={(params) => (
                        <CustomTextField
                          {...params}
                          variant="outlined"
                          fullWidth
                          autoComplete="off"
                          size="small"
                          value={donationAmount}
                          onChange={(e) => {
                            let newValue = e.target.value;
                            if (isNaN(newValue) || parseFloat(newValue) < 0) {
                              newValue = "0";
                            }
                            handleDonationAmount(newValue);
                          }}
                          onKeyDown={(e) => {
                            const parts = e.target.value.split(".");

                            // Allow numeric keys, Backspace, Delete, navigation keys, and decimal point
                            if (
                              !/[0-9]/.test(e.key) &&
                              ![
                                "Backspace",
                                "Delete",
                                "ArrowLeft",
                                "ArrowRight",
                                "ArrowUp",
                                "ArrowDown",
                                ".",
                              ].includes(e.key)
                            ) {
                              e.preventDefault();
                            }
                            // Prevent typing more than two decimal places
                            else if (
                              e.key !== "Backspace" &&
                              parts.length > 1 &&
                              parts[1].length >= 2 &&
                              /[0-9]/.test(e.key)
                            ) {
                              e.preventDefault();
                            }
                            // Prevent typing second decimal point
                            else if (
                              e.key === "." &&
                              e.target.value.includes(".")
                            ) {
                              e.preventDefault();
                            }
                          }}
                          placeholder={t("DonationModal.amount")}
                          helperText={
                            donationAmount !== "" &&
                            parseFloat(donationAmount) <
                              parseFloat(charityDetails.minimumAmount)
                              ? `${t("CampaignDetails.amount_error_msg")} ${
                                  charityDetails.minimumAmount
                                }`
                              : ""
                          }
                          error={
                            donationAmount !== "" &&
                            parseFloat(donationAmount) <
                              parseFloat(charityDetails.minimumAmount)
                          }
                        />
                      )}
                    />
                  ) : (
                    <div style={{ width: "100%" }}>
                      {amountsWithoutZero.length > 0 && (
                        <Select
                          styles={styles}
                          required
                          menuPlacement="bottom"
                          value={amountsWithoutZero
                            .map((amount) => ({
                              label: amount.toString(),
                              value: amount.toString(),
                            }))
                            .find((option) => option.value === donationAmount)}
                          onChange={(option) => setDonationAmount(option.value)}
                          options={amountsWithoutZero.map((amount) => ({
                            label: amount.toString(),
                            value: amount.toString(),
                          }))}
                        />
                      )}
                    </div>
                  )}

                  <h5
                    className="how-often"
                    style={{ fontSize: "14px", textTransform: "uppercase" }}
                  >
                    {t("DonationModal.how_often")} ?
                  </h5>

                  <div className="often-btns-group">
                    {charityDetails?.frequency?.length > 0 &&
                      charityDetails.frequency.map((freq, index) => (
                        <button
                          key={index}
                          type="button"
                          id={freq}
                          className={howOften === freq ? "active" : ""}
                          onClick={(e) => handleHowOften(e.currentTarget.id)}
                        >
                          {t(`DonationModal.${freq.toLowerCase()}`)}
                        </button>
                      ))}
                  </div>

                  {/* if how often is not onetime */}
                  {howOften !== "ONETIME" && (
                    <>
                      <div className="how-often-div">
                        <h5 className="how-often" style={{ fontSize: "14px" }}>
                          #{" "}
                          {charityDetails.donationCampaign ? (
                            <>{t("DonationModal.donations")}</>
                          ) : (
                            <>{t("DonationModal.payments")}</>
                          )}
                          {howOften === "DAILY" &&
                            charityDetails?.endDate &&
                            charityDetails?.endDate !== "9999-12-31 00:00:00" &&
                            charityDetails?.frequency?.length === 1 &&
                            charityDetails.frequency.includes("DAILY") && (
                              <span
                                style={{
                                  background: "#007c16",
                                  color: "#fff",
                                  padding: "4px",
                                  marginLeft: "6px",
                                  borderRadius: "2px",
                                }}
                              >
                                {recurringValue}
                              </span>
                            )}
                        </h5>

                        {!(
                          howOften === "DAILY" &&
                          charityDetails?.endDate &&
                          charityDetails?.endDate !== "9999-12-31 00:00:00" &&
                          charityDetails?.frequency?.length === 1 &&
                          charityDetails.frequency.includes("DAILY")
                        ) && (
                          <>
                            <div className="radio-row">
                              <div className="radio-col">
                                <input
                                  type="radio"
                                  className="recurring-radio"
                                  onChange={() => handleSpecifyTime(false)}
                                  checked={!specifyTime}
                                />
                                {charityDetails.donationCampaign ? (
                                  <>{t("DonationModal.make_this_recurring")}</>
                                ) : (
                                  <>
                                    {t(
                                      "DonationModal.make_this_recurring_payment"
                                    )}
                                  </>
                                )}
                              </div>
                            </div>

                            <div className="radio-row">
                              <div className="radio-col">
                                <input
                                  type="radio"
                                  className="recurring-radio"
                                  onChange={() => handleSpecifyTime(true)}
                                  checked={specifyTime}
                                />
                                {specifyTime ? (
                                  <>
                                    {charityDetails.donationCampaign ? (
                                      <>{t("DonationModal.i_want_to_donate")}</>
                                    ) : (
                                      <>{t("DonationModal.i_want_to_pay")}</>
                                    )}
                                    <input
                                      type="number"
                                      className="number-of-donations-input"
                                      value={recurringValue}
                                      onChange={(e) =>
                                        handleRecurringValue(e.target.value)
                                      }
                                      onFocus={(e) => {
                                        const input = e.target;
                                        const preventScroll = (e) =>
                                          e.preventDefault();
                                        input.addEventListener(
                                          "wheel",
                                          preventScroll,
                                          { passive: false }
                                        );

                                        // Cleanup listener on blur
                                        input.addEventListener(
                                          "blur",
                                          () => {
                                            input.removeEventListener(
                                              "wheel",
                                              preventScroll
                                            );
                                          },
                                          { once: true }
                                        );
                                      }}
                                    />
                                    {t("DonationModal.times")}
                                    <div
                                      className={`minimum-amount-msg${
                                        validRecurring ? "" : " red"
                                      }`}
                                      style={{ marginLeft: `${7}px` }}
                                    >
                                      {t("DonationModal.minimum_recurring")}: 2
                                    </div>
                                  </>
                                ) : (
                                  <>{t("DonationModal.i_want_to_specify")}</>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      </div>

                      <MDBRow className="mb-3 mt-3">
                        <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                          <label
                            htmlFor="startDate"
                            style={{
                              fontSize: "14px",
                              fontWeight: "600",
                              textTransform: "uppercase",
                            }}
                          >
                            {t("DonationModal.start_date")}
                            {howOften !== "ONETIME" && ` - ${howOften}`}
                            {howOften === "WEEKLY" &&
                              charityDetails.recurringDay &&
                              ` - ${
                                days.find(
                                  (day) =>
                                    day.value === charityDetails.recurringDay
                                )?.label || ""
                              }`}
                          </label>

                          <ReactDatePicker
                            style={{
                              width: 100 + "%",
                            }}
                            className="edit-media-inputs"
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            dateFormat="yyyy-MM-dd"
                            placeholderText="Start Date"
                            required
                            minDate={new Date()}
                            filterDate={isSelectableDate}
                          />
                        </MDBCol>
                      </MDBRow>
                    </>
                  )}

                  <div className="back-and-next-div">
                    <MDBBtn
                      type="button"
                      className="back-step-btn"
                      onClick={() => setDonationModal(false)}
                    >
                      {t("DonationModal.back")}
                    </MDBBtn>
                    <MDBBtn
                      type="button"
                      className="next-btn"
                      onClick={handleGoToCardStep}
                      disabled={
                        !validRecurring ||
                        parseFloat(donationAmount) <
                          parseFloat(charityDetails.minimumAmount) ||
                        donationAmount === ""
                      }
                    >
                      {t("DonationModal.next")}
                    </MDBBtn>
                  </div>
                </>
              )}

              {step === 2 && !charityDetails.enableQuantity && (
                <>
                  <div
                    className={`checkbox-container ${
                      isChecked ? "checked" : ""
                    }`}
                  >
                    <label>
                      <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                      />
                      <span>{t("Embed.cover_transaction_costs")}</span>
                      {isChecked && <img src={sparks} alt="sparks" />}
                    </label>
                  </div>

                  <p className="embed-charity-msg">
                    {isChecked ? (
                      <>
                        {t("Embed.by_covering")}{" "}
                        {organizationDetails.currencySymbol}
                        {fees} {t("Embed.message")}
                      </>
                    ) : (
                      <>{t("Embed.would_cover")}</>
                    )}
                  </p>

                  <h1 className="new-amount">
                    {organizationDetails.currencySymbol}
                    {newAmount}
                  </h1>

                  <div className="back-and-next-div">
                    <MDBBtn
                      type="button"
                      className="back-step-btn"
                      onClick={() => setStep(step - 1)}
                    >
                      {t("DonationModal.back")}
                    </MDBBtn>
                    <MDBBtn
                      type="button"
                      className="next-btn"
                      onClick={() => setStep(step + 1)}
                      disabled={
                        !validRecurring ||
                        parseFloat(donationAmount) <
                          parseFloat(charityDetails.minimumAmount) || // extra layer of security if user cheats
                        donationAmount === ""
                      }
                    >
                      {t("DonationModal.next")}
                    </MDBBtn>
                  </div>
                </>
              )}

              {/* card details */}
              {step === 3 && (
                <>
                  <span className="donation-text">
                    {t("DonationModal.enter_card_details")}
                  </span>

                  <form autoComplete="off" onSubmit={handleDonate}>
                    <div className="card-row">
                      <div className="card-col">
                        <label>{t("DonationModal.cardholder_name")}</label>
                        <input
                          type="text"
                          className="edit-media-inputs"
                          value={cardHolderName}
                          onChange={(e) => setCardHolderName(e.target.value)}
                          required
                          placeholder={t("DonationModal.cardholder_name")}
                        />
                      </div>
                    </div>

                    <div className="card-row">
                      <div className="card-col">
                        <label>{t("DonationModal.email")}</label>
                        <input
                          type="email"
                          className="edit-media-inputs"
                          value={cardHolderEmail}
                          onChange={(e) => setCardHolderEmail(e.target.value)}
                          required
                          placeholder={t("DonationModal.email")}
                          readOnly={userData}
                        />
                      </div>
                    </div>

                    <div className="card-row">
                      <div className="card-col">
                        <label>{t("DonationModal.card_number")}</label>
                        <input
                          type="text"
                          className="edit-media-inputs"
                          value={cardNumber}
                          onChange={(e) => setCardNumber(e.target.value)}
                          required
                          placeholder={t("DonationModal.card_number")}
                          pattern=".{15,}"
                          title="Please enter a card number with at least 15 characters"
                        />
                      </div>
                    </div>

                    <div className="card-row">
                      <div className="card-col">
                        <label>{t("DonationModal.expiry_month")}</label>
                        <Select
                          styles={styles}
                          required
                          menuPlacement="top"
                          value={expiryMonthOptions.find(
                            (option) => option.value === expiryMonth
                          )}
                          onChange={(option) => setExpiryMonth(option.value)}
                          options={expiryMonthOptions}
                        />
                      </div>
                      <div className="card-col">
                        <label>{t("DonationModal.expiry_year")}</label>
                        <Select
                          styles={styles}
                          required
                          menuPlacement="top"
                          value={expiryYearOptions.find(
                            (option) => option.value === expiryYear
                          )}
                          onChange={(option) => setExpiryYear(option.value)}
                          options={expiryYearOptions}
                        />
                      </div>
                      <div className="card-col">
                        <label>CVC</label>
                        <input
                          type="text"
                          className="edit-media-inputs"
                          placeholder="CVC"
                          value={cvc}
                          onChange={(e) => setCvc(e.target.value)}
                          required
                        />
                      </div>
                    </div>

                    {transactionError && (
                      <div className="error-msg" style={{ width: 100 + "%" }}>
                        {transactionError}
                      </div>
                    )}

                    <div className="back-and-next-div">
                      <MDBBtn
                        type="button"
                        className="back-step-btn"
                        onClick={() => {
                          charityDetails.enableQuantity
                            ? setDonationModal(false)
                            : setStep(
                                parseFloat(charityDetails.fees) > 0 ? 2 : 1
                              );
                        }}
                        disabled={loader}
                      >
                        {charityDetails.enableQuantity
                          ? `${t("DonationModal.close")}`
                          : `${t("DonationModal.back")}`}
                      </MDBBtn>
                      <MDBBtn
                        type="submit"
                        className="next-btn"
                        disabled={loader}
                      >
                        {charityDetails.donationCampaign ? (
                          <>{t("DonationModal.donate")}</>
                        ) : (
                          <>{t("DonationModal.pay")}</>
                        )}
                      </MDBBtn>
                    </div>
                  </form>
                </>
              )}

              {/* payment processed success screen */}
              {step === 4 && (
                <>
                  {successMsg && (
                    <div className="user-message" style={{ width: 100 + "%" }}>
                      {successMsg}
                    </div>
                  )}
                  <main className="transaction-info-box">
                    <div className="transaction-info">
                      <h5>{t("DonationModal.cardholder_name")}</h5>
                      <h5>{cardHolderName}</h5>
                    </div>
                    <div className="transaction-info">
                      <h5>{t("DonationModal.email")}</h5>
                      <h5>{cardHolderEmail}</h5>
                    </div>
                    <div className="transaction-info">
                      <h5>
                        {charityDetails.enableQuantity
                          ? `${t("CampaignDetails.cost_per_unit")}`
                          : `${t("DonationModal.amount")}`}
                      </h5>
                      <h5>
                        {organizationDetails.currencySymbol}
                        {charityDetails.enableQuantity
                          ? `${charityDetails.minimumAmount}`
                          : `${donationAmount}`}
                      </h5>
                    </div>
                    {parseFloat(charityDetails.fees) > 0 &&
                      !charityDetails.enableQuantity && (
                        <>
                          <div className="transaction-info">
                            <h5>{t("DonationModal.fee")}</h5>
                            <h5>
                              {organizationDetails.currencySymbol}
                              {fees}
                            </h5>
                          </div>
                          <div className="transaction-info">
                            <h5>{t("DonationModal.total_amount")}</h5>
                            <h5>
                              {organizationDetails.currencySymbol}
                              {newAmount}
                            </h5>
                          </div>
                        </>
                      )}
                    {charityDetails.enableQuantity && (
                      <>
                        <div className="transaction-info">
                          <h5>{t("CampaignDetails.quantity")}</h5>
                          <h5>{quantity}</h5>
                        </div>
                        <div className="transaction-info">
                          <h5>{t("DonationModal.total_amount")}</h5>
                          <h5>
                            {organizationDetails.currencySymbol}
                            {newAmount}
                          </h5>
                        </div>
                      </>
                    )}
                    <div className="transaction-info">
                      <h5>{t("DonationModal.billing_period")}</h5>
                      <h5>{howOften}</h5>
                    </div>
                    {howOften !== "ONETIME" && (
                      <>
                        {recurringValue && (
                          <div className="transaction-info">
                            <h5>{t("DonationModal.no_of_recurring")}</h5>
                            {specifyTime ||
                            (howOften === "DAILY" &&
                              charityDetails?.endDate &&
                              charityDetails?.endDate !==
                                "9999-12-31 00:00:00" &&
                              charityDetails?.frequency?.length === 1 &&
                              charityDetails.frequency.includes("DAILY")) ? (
                              <h5>{recurringValue}</h5>
                            ) : (
                              <h5>{t("DonationModal.until_you_specify")}</h5>
                            )}
                          </div>
                        )}
                        <div className="transaction-info">
                          <h5>{t("DonationModal.start_date")}</h5>
                          <h5>{formatDate(startDate)}</h5>
                        </div>
                      </>
                    )}
                    <div className="transaction-info">
                      <h5>{t("DonationModal.organization")}</h5>
                      <h5>{organizationDetails.name}</h5>
                    </div>
                    <div className="transaction-info">
                      <h5>{t("DonationModal.campaign")}</h5>
                      <h5>{charityDetails.name}</h5>
                    </div>
                    <MDBBtn
                      type="button"
                      className="close-modal-btn"
                      onClick={() => setDonationModal(false)}
                    >
                      {t("DonationModal.close")}
                    </MDBBtn>
                  </main>
                </>
              )}
            </div>
          ) : (
            <RegisterDonor
              hostName={hostName}
              setLoggedInUserData={setLoggedInUserData}
              setStep={setStep}
              donationAmount={newAmount}
              howOften={howOften}
              recurringValue={recurringValue}
              fees={fees}
              cardHolderName={cardHolderName}
              donorEmail={cardHolderEmail}
              cardNumber={cardNumber}
              expiryMonth={expiryMonth}
              expiryYear={expiryYear}
              cvc={cvc}
              setSuccessMsg={setSuccessMsg}
              loader={loader}
              setLoader={setLoader}
              setTransactionError={setTransactionError}
              campaignTag={campaignTag}
              nodeTag={nodeTag}
              countries={countries}
              usaStates={usaStates}
              provinces={provinces}
              vTag={vTag}
              startDate={startDate}
              formatDate={formatDate}
            />
          )}
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default DonationModal;
