/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate, Link, useSearchParams } from "react-router-dom";
import { ClapSpinner } from "react-spinners-kit";
import axios from "axios";
import { MDBBtn } from "mdb-react-ui-kit";
import { useDispatch } from "react-redux";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import logo from "../../images/logo.png";
import { cleanData } from "../../redux/searchOrganization/searchActions";
import { CustomTextField } from "./CustomTextField";

// get success msg from LS
function getSuccessMsg() {
  const successMsg = localStorage.getItem("successMsg");
  if (successMsg) {
    return successMsg;
  }

  return null;
}

const Login = ({
  hostName,
  setLoggedInUserData,
  resetSuccessMsg,
  setResetSuccessMsg,
  step,
  setStep,
}) => {
  // navigate
  const navigate = useNavigate();

  // successMsg state
  const [successMsg] = useState(getSuccessMsg());

  // general states
  const [loader, setLoader] = useState(false);

  // step prop
  // 0 = login screen
  // 1 = forgot password screen
  // 2 = new password screen

  const dispatch = useDispatch();
  // login event
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState("");
  const handleLogin = (e) => {
    e.preventDefault();
    setLoginError("");
    setLoader(true);
    axios
      .post(`${hostName}api/v1/auth/login`, {
        email,
        password,
      })
      .then((res) => {
        localStorage.setItem("user", JSON.stringify(res.data.data));
        setLoggedInUserData(res.data.data);
        setResetSuccessMsg("");
        setEmail("");
        setPassword("");
        setLoginError("");
        setLoader(false);
        navigate("/dashboard");
        dispatch(cleanData());
      })
      .catch((err) => {
        localStorage.removeItem("user");
        setLoggedInUserData(null);
        if (!err.response) {
          setLoginError("Something went wrong. Please try again.");
          return;
        }

        if (err.response.data?.errors) {
          // Store the entire errors object in state for detailed error display
          setLoginError(err.response.data.errors);
        } else if (err.response.data?.message) {
          setLoginError(err.response.data.message);
        } else {
          setLoginError("An unknown error occurred. Please try again later.");
        }
        setLoader(false);
      });
  };

  // forget password event
  const [emailForOTP, setEmailForOTP] = useState("");
  const [messageForUser, setMessageForUser] = useState("");
  const [forgetPasswordError, setForgetPasswordError] = useState("");
  const handleForgotPasswordForm = (e) => {
    e.preventDefault();
    setLoader(true);
    setForgetPasswordError("");
    axios
      .put(`${hostName}api/v1/auth/forgetPassword`, {
        email: emailForOTP,
      })
      .then((res) => {
        localStorage.setItem("userEmail", emailForOTP);
        setStep(step + 1);
        setLoader(false);
        setMessageForUser(res.data.message);
        setForgetPasswordError("");
        setLoginError("");
      })
      .catch((err) => {
        setMessageForUser("");
        if (!err.response) {
          setForgetPasswordError("Something went wrong. Please try again.");
          return;
        }

        if (err.response.data?.errors) {
          // Store the entire errors object in state for detailed error display
          setForgetPasswordError(err.response.data.errors);
        } else if (err.response.data?.message) {
          setForgetPasswordError(err.response.data.message);
        } else {
          setForgetPasswordError(
            "An unknown error occurred. Please try again later."
          );
        }
        setLoader(false);
      });
  };

  // reset password event
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [resetPasswordError, setResetPasswordError] = useState("");
  const handleNewPassword = (e) => {
    e.preventDefault();
    const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,}$/;
    setResetPasswordError("");
    if (newPassword === confirmNewPassword) {
      if (passwordRegex.test(newPassword)) {
        setLoader(true);
        axios
          .put(`${hostName}api/v1/auth/resetPassword`, {
            email: emailForOTP,
            resetCode: otp,
            newPassword,
            confirmPassword: confirmNewPassword,
          })
          .then((res) => {
            localStorage.removeItem("userEmail");
            setResetSuccessMsg(res.data.message);
            setStep(0);
            setLoader(false);
            setEmail("");
            setPassword("");
            setLoginError("");
            setEmailForOTP("");
            setMessageForUser("");
            setForgetPasswordError("");
            setOtp("");
            setNewPassword("");
            setConfirmNewPassword("");
            setResetPasswordError("");
          })
          .catch((err) => {
            setLoader(false);
            if (!err.response) {
              setResetPasswordError("Something went wrong. Please try again.");
              return;
            }

            if (err.response.data?.errors) {
              // Store the entire errors object in state for detailed error display
              setResetPasswordError(err.response.data.errors);
            } else if (err.response.data?.message) {
              setResetPasswordError(err.response.data.message);
            } else {
              setResetPasswordError(
                "An unknown error occurred. Please try again later."
              );
            }
          });
      } else {
        setResetPasswordError(
          "Password must be atleast 8 alphanumeric characters."
        );
      }
    } else {
      setResetPasswordError(
        "Confirm Password does not match with the new password."
      );
    }
  };

  // language code

  // lang state
  const [lang, setLang] = useState("");

  // search params
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    // Get language from query param
    const queryLang = searchParams.get("language");

    if (queryLang) {
      setLang(queryLang);
      i18next.changeLanguage(queryLang);
    } else {
      // If no query param, check localStorage
      const storedLang = localStorage.getItem("i18nextLng");
      if (storedLang) {
        setLang(storedLang);
        i18next.changeLanguage(storedLang.toString());
      } else {
        setLang("en");
        i18next.changeLanguage("en");
      }
    }
  }, [lang]);

  // handle language change on button click
  const handleLanguage = (id) => {
    setLang(id);
    i18next.changeLanguage(id);

    // Update query parameter dynamically
    searchParams.set("language", id);
    setSearchParams(searchParams);
  };

  // translation
  const { t } = useTranslation();

  return (
    <div className="background">
      <div className="box login">
        {lang === "en" ? (
          <button
            type="button"
            id="fr"
            onClick={(e) => handleLanguage(e.currentTarget.id)}
            className="change-lang-btn auth"
            disabled={!(loader === false)}
          >
            française
          </button>
        ) : (
          <button
            type="button"
            id="en"
            onClick={(e) => handleLanguage(e.currentTarget.id)}
            className="change-lang-btn auth"
            disabled={!(loader === false)}
          >
            English
          </button>
        )}

        {loader && (
          <div className="loading-screen">
            <ClapSpinner size={30} color="#007c16" loading={loader} />
          </div>
        )}

        <div className="auth-logo">
          <img src={logo} alt="logo" />
        </div>

        {/* successMsg */}
        {successMsg && <div className="user-message">{successMsg}</div>}

        {/* login form */}
        {step === 0 && (
          <form className="auth-form" autoComplete="off" onSubmit={handleLogin}>
            {resetSuccessMsg !== "" && (
              <div className="user-message">{resetSuccessMsg}</div>
            )}

            <h3>{t("Auth.login")}</h3>

            <CustomTextField
              label={t("Auth.email")}
              type="email"
              variant="standard"
              fullWidth
              required
              autoComplete="off"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              readOnly={!!loader}
            />
            <br />
            <br />
            <CustomTextField
              label={t("Auth.password")}
              type="password"
              variant="standard"
              fullWidth
              required
              autoComplete="off"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              readOnly={!!loader}
            />

            <div className="go-signup-text">
              {t("Auth.dont_have_account")}{" "}
              <Link className="green-link" to="/signup">
                {t("Auth.signup")}
              </Link>
            </div>

            <div className="auth-action-btns-div">
              <button
                type="button"
                className="forget-password"
                onClick={() => setStep(step + 1)}
                disabled={!!loader}
              >
                {t("Auth.forget_password")}
              </button>
              <MDBBtn
                type="submit"
                rounded
                className="login-btn"
                disabled={!!loader}
              >
                {t("Auth.login")}
              </MDBBtn>
            </div>

            {loginError && (
              <div className="error-msg">
                {typeof loginError === "string" ? (
                  <>{loginError}</>
                ) : (
                  Object.keys(loginError).map((field, index) => (
                    <p key={index} style={{ marginBottom: 0 }}>
                      {loginError[field][0]}
                    </p>
                  ))
                )}
              </div>
            )}
          </form>
        )}

        {/* forget password form */}
        {step === 1 && (
          <form
            className="auth-form"
            autoComplete="off"
            onSubmit={handleForgotPasswordForm}
          >
            <h3>{t("Auth.forgot_password")}</h3>

            <CustomTextField
              label={t("Auth.email")}
              type="email"
              variant="standard"
              fullWidth
              required
              autoComplete="off"
              value={emailForOTP}
              onChange={(e) => setEmailForOTP(e.target.value)}
              readOnly={!!loader}
            />

            <div className="auth-action-btns-div">
              <button
                type="button"
                className="forget-password"
                onClick={() => setStep(step - 1)}
                disabled={!!loader}
              >
                {t("Auth.back")}
              </button>
              <MDBBtn
                type="submit"
                rounded
                className="login-btn"
                disabled={!!loader}
              >
                {t("Auth.reset")}
              </MDBBtn>
            </div>

            {forgetPasswordError && (
              <div className="error-msg">
                {typeof forgetPasswordError === "string" ? (
                  <>{forgetPasswordError}</>
                ) : (
                  Object.keys(forgetPasswordError).map((field, index) => (
                    <p key={index} style={{ marginBottom: 0 }}>
                      {forgetPasswordError[field][0]}
                    </p>
                  ))
                )}
              </div>
            )}
          </form>
        )}

        {/* reset password form */}
        {step === 2 && (
          <>
            {messageForUser && (
              <div className="user-message">{messageForUser}</div>
            )}

            <form
              className="auth-form"
              autoComplete="off"
              onSubmit={handleNewPassword}
            >
              <h3>{t("Auth.reset_password")}</h3>

              <CustomTextField
                name="otp"
                id="otp"
                label="OTP"
                type="text"
                variant="standard"
                fullWidth
                required
                autoComplete="off"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                readOnly={!!loader}
              />
              <br />
              <br />
              <CustomTextField
                label={t("Auth.new_password")}
                type="password"
                variant="standard"
                fullWidth
                required
                autoComplete="off"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                readOnly={!!loader}
              />
              <br />
              <br />

              <CustomTextField
                label={t("Auth.confirm_password")}
                type="password"
                variant="standard"
                fullWidth
                required
                autoComplete="off"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                readOnly={!!loader}
              />

              <div className="auth-action-btns-div">
                <button
                  type="button"
                  className="forget-password"
                  onClick={() => setStep(0)}
                  disabled={!!loader}
                >
                  {t("Auth.login")}
                </button>
                <MDBBtn
                  type="submit"
                  rounded
                  className="login-btn"
                  disabled={!!loader}
                >
                  {t("Auth.reset")}
                </MDBBtn>
              </div>

              {resetPasswordError && (
                <div className="error-msg" style={{ width: "100%" }}>
                  {typeof resetPasswordError === "string" ? (
                    <>{resetPasswordError}</>
                  ) : (
                    Object.keys(resetPasswordError).map((field, index) => (
                      <p key={index} style={{ marginBottom: 0 }}>
                        {resetPasswordError[field][0]}
                      </p>
                    ))
                  )}
                </div>
              )}
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default Login;
